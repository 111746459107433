import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-home-redesign',
  templateUrl: './home-redesign.component.html',
  styleUrls: ['./home-redesign.component.css']
})
export class HomeRedesignComponent implements OnInit {
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 1000,
    // responsive: [
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 4
    //     }
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1
    //     }
    //   }
    // ]
  };



  slideConfigConstant = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
   
    // responsive: [
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 4
    //     }
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1
    //     }
    //   }
    // ]
  };


mainData=[
 {
   heading:"INTRODUCTION",
   para:"Empowering Businesses",
   paraTwo:"Through",
   span:"Digital Excellence",
   content:"Reimagine your business with the best software development solutions to help you design, define and deliver results in the modern era. Code Fusion serves you with new technologies to ...",
  
 },
 {
  heading:"INTRODUCTION",
  para:"Empowering Businesses",
  paraTwo:"Through",
  span:"Digital Excellence",
  content:"Reimagine your business with the best software development solutions to help you design, define and deliver results in the modern era. Code Fusion serves you with new technologies to ...",
 
},
{
  heading:"INTRODUCTION",
  para:"Empowering Businesses",
  paraTwo:"Through",
  span:"Digital Excellence",
  content:"Reimagine your business with the best software development solutions to help you design, define and deliver results in the modern era. Code Fusion serves you with new technologies to ...",
 
},
{
  heading:"INTRODUCTION",
  para:"Empowering Businesses",
  paraTwo:"Through",
  span:"Digital Excellence",
  content:"Reimagine your business with the best software development solutions to help you design, define and deliver results in the modern era. Code Fusion serves you with new technologies to ...",
 
},
{
  heading:"INTRODUCTION",
  para:"Empowering Businesses",
  paraTwo:"Through",
  span:"Digital Excellence",
  content:"Reimagine your business with the best software development solutions to help you design, define and deliver results in the modern era. Code Fusion serves you with new technologies to ...",
 
}

]

   testimonials2 = [
    {
      name: 'Olivia Moore',
      position: 'Marketing Manager',
      review: "Exceptional service and creativity! The web designers at The Code Vision exceeded our expectations."
    },
    {
      name: 'Benjamin Carter',
      position: 'Sales Director',

      review: "Working with The Code Vision designers was a fantastic experience. Their professionalism is unmatched!"
    },
    {
      name: 'Isabella Parker',
      position: 'Product Manager',

      review: "The Code Vision's designers are truly talented! They exceeded all our expectations."
    },
    {
      name: 'William White',
      position: 'CEO',

      review: "Impressed by the expertise and commitment of The Code Vision team. They are highly recommended!"
    },
    {
      name: 'Sophia Evans',
      position: 'Head of Operations',

      review: "Exceptional service and attention to detail! The Code Vision's designers are top-notch."
    },
    {
      name: 'Ethan Garcia',
      position: 'Marketing Manager',

      review: "The Code Vision team's work ethic is exceptional! Pleasure working with such professionals."
    },
    {
      name: 'Ava Martinez',
      position: 'Sales Director',

      review: "Absolutely thrilled with the outcome! The Code Vision designers are phenomenal."
    },
    {
      name: 'Mason Lopez',
      position: 'Product Manager',

      review: "The Code Vision team went above and beyond! Their dedication and creativity are commendable."
    },
    {
      name: 'Harper Adams',
      position: 'Head of Operations',

      review: "Working with The Code Vision was a breeze! Highly impressed by their professionalism."
    },
    {
      name: 'Samuel Wilson',
      position: 'Marketing Manager',

      review: "The Code Vision team's expertise is second to none! Outstanding service overall."
    },
    {
      name: 'Evelyn Rivera',
      position: 'CEO',

      review: "Impressed by the seamless web designing journey with The Code Vision. They delivered beyond expectations!"
    },
    {
      name: 'Michael Scott',
      position: 'Sales Director',

      review: "The Code Vision designers are truly great professionals to work with! Highly recommended."
    },
    {
      name: 'Emily Phillips',
      position: 'Marketing Manager',

      review: "The Code Vision team's attention to detail is remarkable! Exceptional service and commitment."
    },
    {
      name: 'Logan Cooper',
      position: 'Sales Director',

      review: "Highly impressed by the creativity and professionalism of The Code Vision's designers!"
    },
    {
      name: 'Mia Turner',
      position: 'Head of Operations',

      review: "The Code Vision designers brought our vision to life! Their dedication is commendable."
    },

    ]  

limitLoop=[
  {
    number:1
  },
  {
    number:1
  },
  {
    number:1
  }
]
    
 testimonials = [
    {
      name: 'Emily Johnson',
      position: 'Marketing Manager',

      review: "The Code Vision team made our web designing journey seamless and efficient.",
    },
    {
      name: 'David Thompson',
      review: "Exceptional service and creativity! The web designers at The Code Vision exceeded our expectations.",
      position: 'Head of Operations',
    },
    {
      name: 'Samantha Miller',
      review: "Professionalism at its best! The Code Vision's designers are top-notch. Pleasure working with them!",
      position: 'Marketing Manager',
    },
    {
      name: 'Michael Davis',
      review: "Flawless execution! The Code Vision designers exceeded all expectations.",
      position: 'CEO',
    },
    // ... continue adding more testimonials in a similar format
    {
      name: 'Noah Hernandez',
      review: "Highly impressed by the creativity and professionalism! The Code Vision designers are exceptional.",
      position: 'Product Manager',
    },
    {
      name: 'Sophia Nguyen',
      review: "The Code Vision team went above and beyond! Their dedication is commendable.",
      position: 'Marketing Manager',
    },
    {
      name: 'Alexander Scott',
      review: "Flawless execution! The Code Vision designers exceeded all expectations.",
      position: 'Head of Operations',
    },
    {
      name: 'Mia Thompson',
      review: "Working with The Code Vision was a pleasure! Their designers are truly talented.",
      position: 'Marketing Manager',
    },
    {
      name: 'Liam Baker',
      review: "The Code Vision team's expertise is second to none! A fantastic experience overall.",
      position: 'Product Manager',
    },
    {
      name: 'Charlotte Turner',
      review: "Top-notch service! The Code Vision designers are highly skilled and reliable.",
      position: 'Head of Operations',
    },
    // Add more
  ]  

  categoryNew = [
    {
      image: "web-development-img",
      title: "Web Development",
      path:"/web-development",
      content: "Our web development services are sure to deliver realistic and wonderful business results."
    },
    {
      image: "android-dev-img",
      path:"/android-development",
      title: "Android Development",
      content: "We can develop wide-ranging Android apps that synchronize the Internet and mobile platforms."
    },
    {
      image: "ios-img",
      path:"/ios-development",

      title: "IOS Development",
      content: "With cutting-edge iOS development tools and technology, we can create amazing iPhone Apps."
    },
    {
      image: "seo-img",
      path:"/seo-services",

      title: "SEO Services",
      content: "We design a failsafe SEO strategy that can pull in your target customers in 2020."
    },
    {
      image: "web-development-img",
      path:"/js-programers",

      title: "JS Programers",
      content: "Our JS programmers have a great mastery of languages, for example, PHP, Javascript, React, etc."
    },
    {
      image: "ios-img",
      path:"/Specialization",

      title: "Specialization in JS",
      content: "Our specialization in JS helps us design next-generation web apps with a dependable and world-class design."
    },
  ]
  category = [
    {
      image: "../../../../assets/images/clients/gray-pc.svg",
      title: "Web Development",
      content: "Our web development services are sure to deliver realistic and wonderful business results."
    },
    {
      image: "../../../../assets/images/clients/android-gray.svg",

      title: "Android Development",
      content: "We can develop wide-ranging Android apps that synchronize the Internet and mobile platforms."
    },
    {
      image: "../../../../assets/images/clients/ios-gray.svg",

      title: "IOS Development",
      content: "With cutting-edge iOS development tools and technology, we can create amazing iPhone Apps."
    },
    {
      image: "../../../../assets/images/clients/search-gray.svg",

      title: "SEO Services",
      content: "We design a failsafe SEO strategy that can pull in your target customers in 2020."
    },
    {
      image: "../../../../assets/images/clients/js-gray.svg",

      title: "JS Programers",
      content: "Our JS programmers have a great mastery of languages, for example, PHP, Javascript, React, etc."
    },
    {
      image: "../../../../assets/images/clients/book-with-body.svg",

      title: "Specialization in JS",
      content: "Our specialization in JS helps us design next-generation web apps with a dependable and world-class design."
    },
  ]

  grow = [
    {
      image: "../../../../assets/images/clients/expert.svg",
      image2: "../../../../assets/images/clients/white-expert-icon.svg",

      title: "Expert Guidance",
      content: "Benefit from the wisdom of seasoned professionals who have been through the startup journey. Our experienced team is here to offer guidance, share insights, and provide mentorship to help you navigate the complexities of the business landscape."
    },
    {
      image: "../../../../assets/images/clients/collaborative.svg",
      image2: "../../../../assets/images/clients/white-community-icon.svg",

      title: "Collaborative Community",
      content: "Join a vibrant community of like-minded entrepreneurs. Our collaborative spaces foster networking opportunities, idea exchanges, and the chance to build lasting connections with fellow startups, creating an ecosystem where success is shared."
    },
    {
      image: "../../../../assets/images/clients/bulb-on-hand.svg",
      image2: "../../../../assets/images/clients/white-bulb-on-hand-icon.svg",

      title: "Tailored Solutions",
      content: "We understand that startups have unique needs. Our services are not one-size-fits-all; they are crafted to suit the specific requirements of your budding venture. From web development to strategic planning, we've got you covered."
    },
  
  
  ]


  choose = [
    {
      image: "../../../../assets/images/clients/p-icon.svg",
      title: "10+ years of experience",
      content: "At Code Fusion, our vision is to see grow profoundly as your competitor through our excellent range or. Web design and development Solution."
    },
    {
      image: "../../../../assets/images/clients/engineer.svg",

      title: "100+ Qualified Engineers",
      content: "100+ qualified software engineers to choose from with 100% confidentiality according to your specific needs."
    },
    {
      image: "../../../../assets/images/clients/work-human.svg",

      title: "On demand workforce",
      content: "Work in various shifts to match different international time zones."
    },
    {
      image: "../../../../assets/images/clients/headphone.svg",

      title: "24/7 Customer Support",
      content: "24/7 customer support via email, chat and phone."
    },
    {
      image: "../../../../assets/images/clients/pricing.svg",

      title: "Competitive Pricing",
      content: "All the services at your finger-tip at the most Affordable prices & No hidden cost."
    },
   
  ]

  excellence=[
    {
      number:"100+",
      reaction:"Happy Customer",
      words:"We proud that",
      backgroundColor:"light-red",
      fontColor:"red-font"
    },
    {
      number:"200+",
      reaction:"Project Completed",
      words:"Done with excellence",
      backgroundColor:"light-yellow",
      fontColor:"yellow-font"
    }
    ,
    {
      number:"150+",
      reaction:"Websites optimized",
      words:"Great achievement",
      backgroundColor:"light-green",
      fontColor:"green-font"

    },
    {
      number:"10000+",
      reaction:"Working Hours",
      words:"We optimize our timeline",
      backgroundColor:"light-orange",
      fontColor:"orange-font"

    }

  ]

  limit=[
    {
      number:"1"
    },
    {
      number:"2"
    },
    {
      number:"3"
    },
    {
      number:"1"
    },
    {
      number:"2"
    },
    {
      number:"3"
    },
    {
      number:"1"
    },
    {
      number:"2"
    },
    {
      number:"3"
    },
    {
      number:"1"
    },
    {
      number:"2"
    },
    {
      number:"3"
    },
  ]





















  constructor(private cdr:ChangeDetectorRef) { }
















  ngOnInit(): void {
    setInterval(this.scrollHorizontally, 1000); 
    this.cdr.detectChanges()
  }


  scrollHorizontally() {
    const elements = document.querySelectorAll('.scroll');
    elements.forEach((element) => {
        // Adjust the scrollLeft value to control the horizontal scroll
        element.scrollTo({
            left: element.scrollLeft + 500, // Change this value to adjust the scroll speed
            behavior: 'smooth' // Optional: adds smooth scrolling effect
        });
    });
}

// Set interval to trigger scrolling for elements with the 'scroll' class after 1 second




}
