import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

stands=[
  {
    image:" ../../../../../../assets/images/clients/cloud-with--tick.svg",
    heading:"Client success",
    content:"What sets us apart is our commitment to client success. We don't just build websites; we cultivate partnerships. From ideation to execution, our collaborative approach ensures that every project aligns seamlessly with our clients' objectives. We take pride in being more than a service provider; we are your strategic ally in the digital realm."
  },
  {
    image:" ../../../../../../assets/images/clients/strength.svg",
    heading:"Strength",
    content:"Diversity is our strength. Our team comprises seasoned developers, ingenious designers, and visionary strategists, each contributing their unique skills to the synergy of our projects. This diversity allows us to approach challenges with multifaceted perspectives, resulting in innovative solutions that break new ground."
  },

  {
    image:" ../../../../../../assets/images/clients/commitment.svg",
    heading:"Commitment",
    content:"In an ever-evolving digital landscape, staying ahead is not an option; it's a necessity. Our commitment to continuous learning and adaptation keeps us at the forefront of industry trends. We leverage the latest technologies and methodologies to ensure that your web presence is not just current but future-proof.GGG"
  },
  {
    image:" ../../../../../../assets/images/clients/three-blue-circle.svg",
    heading:"Transparency and integrity",
    content:"Transparency and integrity form the cornerstone of our interactions. From project timelines to budget considerations, we believe in open communication to build trust and foster long-term relationships. Our clients are not just clients; they are partners on a shared journey towards digital success."
  },
]

  constructor() { }

  ngOnInit(): void {
  }

}
