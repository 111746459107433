import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  menuShow = false;
  isHeaderSticky = false;
  readMore = false;
  scrollProgress = 0;
  activeMenu = 'home';
  isMenuOpen = false;
  isNavOverlayVisible = false;
  isContentOverlayVisible = false;
  isNavVisible = false;
  currentUrl = ''
  showheader :boolean = true
  expertiseOpened = false
  aboutUs = false
  services=false
  technology=false
  industryOpened=false
  
  show:any=false;

  options: any = [
    {
      imagePath: '../../../../assets/images/clients/chatbot.svg',
      category: 'Chatbot Development',
      path: '/expertise/chatbot-development',
      backgroundColor: 'custom-light-blue',
    },
    {
      imagePath:
        '../../../../assets/images/clients/progressive.svg',
      category: 'Progressive Web Apps',
      path: '/expertise/progressive-web-apps',
      backgroundColor: 'custom-light-purple',
    },
    {
      imagePath: '../../../../assets/images/clients/meta.svg',
      category: 'Metaverse & AR/VR',
      path: '/expertise/metaverse-arvr',
      backgroundColor: 'custom-light-pink',
    },
    {
      imagePath: '../../../../assets/images/clients/machine.svg',
      category: 'Machine Learning',
      path: '/expertise/machine-learning',
      backgroundColor: 'custom-light-green',
    },
  
    {
      imagePath: '../../../../assets/images/clients/cloud.svg',
      category: 'Cloud Computing',
      path: '/expertise/cloud-computing',
      backgroundColor: 'custom-light-yellow',
    },

   
    {
      imagePath:
        '../../../../assets/images/clients/blockchain.svg',
      category: 'Blockchain Development',
      path: '/expertise/blockchain-development',
      backgroundColor: 'custom-light-red',
    },
  ];

  industry: any = [
    {
      imagePath: '../../../../assets/images/clients/meta.svg',

      category: 'Education & eLearning',
      path: '/expertise/education-elearning',
      backgroundColor: 'custom-light-pink',
    },
    {
      imagePath: '../../../../assets/images/clients/chatbot.svg',
      category: 'Pharma & Healthcare',
      path: '/expertise/pharma-healthcare',
      backgroundColor: 'custom-light-blue',
    },
    {
      imagePath: '../../../../assets/images/clients/cloud.svg',

            category: 'Automobile & Logistics',
      path: '/expertise/automobile-logistics',
      backgroundColor: 'custom-light-yellow',
    },
    {
      imagePath: '../../../../assets/images/clients/machine.svg',
      category: 'Retail & eCommerce',
      path: '/expertise/retail-eCommerce',
      backgroundColor: 'custom-light-green',
    }
  ];


  constructor(private router: Router ,private route: ActivatedRoute) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // Get URL path when navigation ends
        this.currentUrl = this.router.url;
        const segments = this.currentUrl.split('/');
    
        // Find the last segment (after the last '/')
        const lastSegment = segments[segments.length - 1];
        let firstSegment= "";
       if(segments.length >= 2) firstSegment  = segments[1];
       
        // Split the last segment by '#'
        const parts = lastSegment.split('#');
    
        // Extract segments and fragment
        const urlSegments = parts[0]; // Segments before the '#'
        const fragment = parts[1];   // Fragment after the '#'
    
        console.log("lastSegment:", lastSegment);
    
        this.activeMenu = 'home';
        if (lastSegment === 'expertise' || firstSegment == "expertise"  ) {
          this.activeMenu = 'expertise';
        } else if (lastSegment ==='about-us') {
          this.activeMenu = 'about-us';


        }else if (lastSegment ==='contact-us') {
          this.activeMenu = 'contact-us';


        }
        else if (lastSegment ==='case-study') {
          this.activeMenu = ' ';


        }
        else if (lastSegment ==='web-development') {
          this.activeMenu = ' ';


        }
        else if (lastSegment ==='our-teams') {
          this.activeMenu = ' ';


        }
        else if (lastSegment ==='our-teams') {
          this.activeMenu = ' ';


        }
        else if (lastSegment ==='contact-us') {
          this.activeMenu = 'contact-us';


        }
        if(fragment) {
          this.activeMenu = fragment;
        }
        // console.log('fragment',fragment)
    
        this.showheader = urlSegments === 'home';
      }
    });
    
  }

  ngOnInit(): void {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: any) {
    if (!this.isMenuOpen) {
      this.isHeaderSticky = window.pageYOffset > 300;
      this.scrollProgress =
        (window.pageYOffset /
          (document.body.scrollHeight - window.innerHeight)) *
        100;
    }
  }
  scrollByOnePixel() {
    window.scrollBy(0, 1); // Scroll down by 1px
  }

  // HostListener to detect click event on the link
  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    if (target.classList.contains('nav-link')) {
      this.scrollByOnePixel(); // Call scroll function when the link is clicked
    }
  }

  
  openMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      this.isHeaderSticky = false;
      this.isNavOverlayVisible = true;
      setTimeout(() => {
        this.isNavVisible = true;
      }, 400);
    } else {
      this.isNavVisible = false;
      this.isContentOverlayVisible = true;
      setTimeout(() => {
        this.onWindowScroll({});
        this.isNavOverlayVisible = false;
        this.isContentOverlayVisible = false;
      }, 600);
    }
  }



  navigateToChatbotDevelopment(path: string) {
    console.log('path', path);
    // Use the Angular Router to navigate to the chatbot development route
    this.router.navigate([path]); // Adjust the route path based on your configuration
  }

  switchTech(show:any){
    this.show = show
  }
 
 

}
