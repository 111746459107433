<div class="container-fluid pt-md-4  " data-aos="fade-up">
    <div class="row px-md-5 pt-md-5">
        <div class="col-md-11 work-wrapper pb-md-0 pb-4 m-auto d-flex align-items-center ">


            <div class="col-md-6 col-12">
                <div class=" out-work-text ps-md-5  ms-md-3">
                    Portfolio

                    <div class="border-bottom"></div>
                    <div class="out-you-code">
                        <span class="out-creative-text font-21">
                            "Transforming visions into reality” – Our latest project exemplifies innovation, precision,
                            and a commitment to excellence. Explore the embodiment of success in our portfolio. </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12 p-0 d-flex justify-content-center">
                <img src="../../assets/images/clients/our-work.svg" alt="" class="our-work">

            </div>

        </div>
    </div>
</div>

<section>


    <div class="container section-code-fusion py-5">
        <div class="col-lg-12">
            <div class="text-center broken-code">
                <button type="button" class="styled-btn hover-purple btn-outline-primary"
                    (mouseover)="toggleHover(true)" (mouseout)="toggleHover(false)">
                    <img *ngIf="isHovered" src="../../../../../../assets/images/clients/three-lines-differ.svg"
                        alt="team" class="home-screen me-2" />
                    <img *ngIf="!isHovered" src="../../../../../../assets/images/clients/three-lines-gray.svg"
                        alt="team-hover" class="home-screen me-2" />
                    All
                </button>
                <button type="button" class="styled-btn hover-purple btn-outline-secondary  mx-3"
                    (mouseover)="toggleHoverPc(true)" (mouseout)="toggleHoverPc(false)">
                    <img *ngIf="!isHoveredPc" src="
            ../../../../../../assets/images/clients/pc.svg" alt="team" class="home-screen me-2" />
                    <img *ngIf="isHoveredPc" src="
            ../../../../../../assets/images/clients/pc-white.svg" alt="team" class="home-screen me-2" />
                    Web Template
                </button>
                <button type="button" class="styled-btn hover-purple color-gray" (mouseover)="toggleHoverPhone(true)"
                    (mouseout)="toggleHoverPhone(false)">

                    <img *ngIf="!isHoveredPhone" src="
                ../../../../../../assets/images/clients/phone.svg" alt="team" class="home-screen me-2 " />
                    <img *ngIf="isHoveredPhone" src="
                ../../../../../../assets/images/clients/phone-white.svg" alt="team" class="home-screen me-2 " />

                    Mobile Apps

                </button>
            </div>
        </div>
        <div class="row pb-5 mt-5 mb-4">
            <div class="col-lg-4 col-md-6 mb-4 mb-lg-4  " *ngFor="let item of clients let index = index">
                <!-- Card-->
                <div class="card  new-out-work ">
                    <div class="card-body p-4 text-center ">
                        <div class="">

                            <img [src]="item.image" alt="team" class=" text-center" />
                        </div>

                        <h5 class="card-title text-center mt-2 upper-text">{{item.name}}</h5>
                        <div class="text-center">
                            <span class="btn-code-text btn-outline-primary text-center px-2 py-1">{{item.category}}</span>


                        </div>
                        <ng-container *ngIf="index === 2 || index === 4 || index === 8">
                            <img src="../../../../../../assets/images/clients/blank-mobile.png" alt="newImage" class="blank-mobile" />
                            <img src="../../../../../../assets/images/clients/blank-mobile.png" alt="newImage" class="blank-mobile-2 ms-3 mt-5" />
                            <div class="margin-negative-mobile">
                                <img [src]="item.site" alt="site" class="sites-mobile" />
                            </div>
                            <div class="margin-negative-mobile-3">
                                <img [src]="item.site" alt="site" class="sites-mobile" />
                            </div>
                        </ng-container>
                        <ng-container *ngIf="index !== 2 && index !== 4 && index !== 8">
                            <div class="blank-pc">
                                <img src="../../../../../../assets/images/clients/blank-pc.png" alt="team" class="desktop-work" />
                                <div class="margin-negative">
                                    <img [src]="item.site" alt="site" class="sites" />
                                </div>
                            </div>
                        </ng-container>
                        <p class="card-text text-code-element mt-md-5 mt-4">
                            {{item.content}}
                        </p>

                    </div>
                </div>
            </div>

        </div>

        <div class="text-center  mt-4">

            <button type="button" class=" learn-more-trick" data-bs-toggle="button" autocomplete="off">Load More
                <img src="
                    ../../../../../../assets/images/clients/white-right-arrow.svg" alt="team"
                    class=" " />

            </button>


        </div>

    </div>
</section>


<!-- contact section -->
<section>
    <div class="container-fluid " data-aos="fade-up">





        <div class="row pb-md-4 pb-3 pt-md-5 pt-3 mt-md-5">
            <div class="col-md-10 col m-auto   py-md-5 py-3  ">
                <p class="color-purple font-14 font-weight-600 mb-0"> <span class="styled-14 px-3">CONTACT US </span>
                </p>
                <p class="font-28 font-weight-600 color-purple">LET'S CONNECT </p>
                <p class="color-gray-2">Fill the form below and we will be in touch with you shortly</p>

            </div>




        </div>

        <div class="row pb-md-4 pb-3 pt-md-5 pt-3  background-purple">
            <div class="col-md-10 col m-auto text-white  p-md-4 p-3  ">
                <div class="row mb-md-5 mb-3">
                    <div class="col-md-6 col-12">
                        <div class="d-flex mb-md-4 mb-2">
                            <div class="me-3">
                                <img src="../../../../assets/images/clients/location.svg" alt="location.svg">
                            </div>
                            <div>
                                <p class="   mb-0">

                                    41/75 Thaper Colony
                                </p>
                                <p>
                                    Jagadhri, (135001)

                                </p>
                            </div>
                        </div>

                        <div class="mb-md-5 mb-2">
                            <p class=" mb-0">
                                <img src="../../../../assets/images/clients/mobile.svg" alt="mobile.svg">
                                +91 8837624775 , +91 7709727784
                            </p>
                        </div>

                        <div class="d-flex ">
                            <div class="me-3">
                                <img src="../../../../assets/images/clients/mail.svg" alt="mail">

                            </div>
                            <div>
                                <p class="mb-0 text-white">
                                    <a href="mailto:hi@thecodefusion.com" class="text-white">hi@thecodefusion.com</a>
                                </p>
                                <p>
                                    <a href="mailto:shivam@thecodefusion.com"
                                        class="text-white">shivam@thecodefusion.com</a>
                                </p>

                            </div>
                        </div>

                        <p class="">
                        </p>
                    </div>
                    <div class="col-md-6 col-12">

                        <div class="row px-md-4 px-2 py-md-5 py-3  rounded-20 bg-white box-shadow">
                            <div class="col-md-6"><input type="text" placeholder="Full Name"></div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Email Name">
                            </div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Phone Number ">
                            </div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Skype id / whatsapp number">
                            </div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Interested in">
                            </div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Your Budget">
                            </div>
                            <div class="col-md-12">
                                <textarea placeholder="Enter your query" class="custom-textarea"></textarea>
                            </div>
                            <div>

                            </div>
                            <div class="d-flex col-md-12 justify-content-end">

                                <button type="submit" class="submit-btn ">
                                    Submit <img src="../../../../assets/images/clients/white-right-arrow.svg"
                                        alt="arrow" class="ms-3">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>