<div class="container-fluid" data-aos="fade-up">
  <div class="row" data-aos="fade-up">
    <div class="col px-0">
      <img src="../../../../assets/images/clients/case.png" alt="case" class="case">
      <p class="case-study font-weight-600 font-54 text-white">Case Study</p>
    </div>

  </div>
  <div class="row px-md-5  px-3 py-md-5 py-3 mx-md-5 mx-2 rounded-20 bg-white shadow negative-margin  "
    data-aos="fade-up">
    <div class="col-md-6 px-md-5 px-2 bg-white">
      <p class="font-24 color-green font-weight-600"><img src="../../../../assets/images/clients/signal-green.png"
          alt="signal" class="signal">Shopkeeper</p>
      <p class="font-24 font-weight-600">Transformative Digital Presence for <span
          class="color-purple underline cursor-pointer"><a href="/" class="link-style-none">shopkeeper.com</a></span>
      </p>
      <p> <span class="font-weight-600 underline font-16 cursor-pointer"><a href="/"
            class="link-style-none-2">Shopkeeper.com</a></span>, a leading enterprise in the industry, approached Code
        Fusion Private Limited with a vision
        to revamp its online presence and streamline its digital operations.</p>
    </div>
    <div class="col-md-6 px-0">
      <div class="row justify-content-center">
        <div class="col-md-5 right-bottom-border py-3" *ngFor="let item of case">
          <div data-aos="fade-up">
            <p class="font-36 color-green font-weight-600 mb-0">{{item.percent}}</p>
            <p class="font-16 color-gray mb-0">{{item.condition}}</p>
            <p class="font-16">{{item.speed}}</p>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>








<div class="five-gradient-bg custom-margin-top py-md-5 py-3  " data-aos="fade-up">


  <div class="container-fluid px-md-5 my-5 py-md-0 py-3">
    <div class="row px-md-5  border-purple px-3 py-md-5 py-3 mx-md-4 mx-2 bg-white rounded-20 ">
      <div class="col-md-6 curved-arrow font-16 px-md-5 px-3 line-height">
        <div class="white-border">
          <p class="over-line px-md-4 px-3 font-30 font-weight-500 color-dark-gray">Challenges</p>


        </div>
        <p class=" my-md-0 my-3">

          Shopkeeper Corporation faced challenges with its outdated website, resulting in poor user experience and
          hindered customer engagement. The existing platform was not scalable, and the lack of mobile responsiveness
          posed a significant barrier to reaching a broader audience.
        </p>
      </div>
      <div class="col-md-6 d-flex justify-content-center" data-aos="fade-up">
        <img src="../../../../assets/images/clients/challenge.png" alt="pc" class="h-300">
      </div>
    </div>
  </div>



  <div class="container-fluid px-md-5 my-5 py-md-5 py-3" data-aos="fade-up">
    <div class="row px-md-5  border-purple px-3 py-md-5 py-3 mx-md-4 mx-2 bg-white rounded-20 ">
      <div class="col-md-6 curved-arrow d-flex justify-content-center">
        <div class="white-border-2">

          <p class="over-line-2 px-md-4 px-3 font-30 font-weight-500 color-dark-gray">Our Approach</p>
        </div>
        <img src="../../../../assets/images/clients/case-pc.png" alt="pc" class="h-300  my-md-0 my-3">
      </div>
      <div class="col-md-6  font-16 px-md-5 px-3 line-height" data-aos="fade-up">
        <div *ngFor="let item of text" data-aos="fade-up">

          <p class="font-16">

            <span class="font-weight-600">{{item.heading}}:</span> {{item.para}}
          </p>
        </div>
      </div>

    </div>
  </div>

  <div class="container-fluid px-md-5 my-5 py-md-5 py-3" data-aos="fade-up">
    <div class="row px-md-5  border-purple px-3 py-md-5 py-3 mx-md-4 mx-2 bg-white rounded-20 ">

      <div class="col-md-6 curved-arrow  font-16 px-md-5 px-3 line-height">
        <div class="white-border-3">

          <p class="over-line-3 px-md-4 px-3 font-30 font-weight-500 color-dark-gray">Results</p>
        </div>
        <div *ngFor="let item of results" data-aos="fade-up" class=" my-md-0 my-3">

          <p class="font-16">

            <span class="font-weight-600">{{item.heading}}:</span> {{item.para}}
          </p>
        </div>
      </div>
      <div class="col-md-6  d-flex justify-content-center" data-aos="fade-up">

        <img src="../../../../assets/images/clients/case-pc-mobile.png" alt="pc" class="h-500">
      </div>
    </div>
  </div>


  <div class="container-fluid mb-md-5 mb-3 " data-aos="fade-up">
    <div class="row p-star mx-md-0 mx-2">
      <div class="col-md-8 m-auto  background-pink font-16 custom-border p-md-5 p-3 rounded-20">
        <p><img src="../../../../assets/images/clients/fly-star.svg" alt="star-icon "> <span
            class="font-36 font-weight-500 ">Conclusion</span></p>
        <p class="font-16 my-md-0 my-3">
          Through strategic planning, collaborative development, and a user-centric approach, Code Fusion Private
          Limited, successfully transformed the digital presence of Shopkeeper.com successfully. The
          case highlights our commitment to delivering tailored solutions that align with our clients' objectives,
          fostering growth and positive brand perception in the digital realm.
        </p>

      </div>
    </div>
  </div>
</div>

<section data-aos="fade-up" class="section-contact py-5" style="overflow: visible;" id="contact-us">
  <div class="container-fluid">

    <div class="row" style="overflow: hidden;">
      <div class="col-12 text-center text-white">
        <h1 class="display-4 font-weight-bold">LET'S CONNECT</h1>
        <h6 class="">Fill the form below and we will be in touch with you shortly</h6>
      </div>
      <div class="col-12 my-4 mb-md-5 text-center text-white">
        <h5 class="pb-2">I'M REPRESENTATIVE OF A:</h5>
        <div class="row justify-content-center">
          <div class="col-auto btn-contact-wrap" [class.active]="!isLargeCompany" (click)="isLargeCompany= false">
            <button type="button" class="btn btn-circle btn-contact">
              <img src="../../../../assets/images/icons/wind.svg" alt="">
            </button>
            <h6 class="btn-contact-label">Small Business</h6>
          </div>
          <div class="col-auto btn-contact-wrap" [class.active]="isLargeCompany" (click)="isLargeCompany= true"><button
              type="button" class="btn btn-circle btn-contact">
              <img src="../../../../assets/images/icons/activity.svg" alt="">
            </button>
            <h6 class="btn-contact-label">Large Business</h6>
          </div>
        </div>
      </div>
      <div class="col-md-6 my-2 px-4 pr-5 order-md-last">
        <form class="form-theme" formGroup="contactForm">
          <div class="form-group mb-5">
            <label>Full Name</label>
            <input type="text" class="form-control" formControlName="name" placeholder="How should we call you?">
            <small class="form-text text-danger" *ngIf="isControlHasError('name','required')">
              <strong>This field is required</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('name','minlength')">
              <strong>Minimum length limit is 3</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('name','maxlength')">
              <strong>Maximum length limit is 100</strong>
            </small>
          </div>
          <div class="form-group my-5">
            <label>Phone</label>
            <input type="tel" class="form-control" formControlName="phone" placeholder="Enter valid phone number.">
            <small class="form-text text-danger" *ngIf="isControlHasError('phone','required')">
              <strong>This field is required</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('phone','pattern')">
              <strong>Invalid phone</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('phone','minlength')">
              <strong>Minimum length limit is 10</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('phone','maxlength')">
              <strong>Maximum length limit is 10</strong>
            </small>
          </div>
          <div class="form-group my-5">
            <label>Email Address</label>
            <input type="email" class="form-control" formControlName="email" placeholder="Enter a valid email address.">
            <small class="form-text text-danger" *ngIf="isControlHasError('email','required')">
              <strong>This field is required</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('email','email')">
              <strong>Invalid email address</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('email','minlength')">
              <strong>Minimum length limit is 3</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('email','maxlength')">
              <strong>Maximum length limit is 100</strong>
            </small>
          </div>
          <div class="form-group my-5">
            <label>Your Message</label>
            <textarea class="form-control" rows="3" formControlName="message"
              placeholder="Tell us more about your project."></textarea>
            <small class="form-text text-danger" *ngIf="isControlHasError('message','required')">
              <strong>This field is required</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('message','minlength')">
              <strong>Minimum length limit is 20</strong>
            </small>
          </div>
          <a class="btn-group btn-split btn-split-secondary btn-hover-light btn-split-sm" role="group"
            aria-label="Basic example">
            <button type="button" class="btn" (click)="submitContactForm()"
              [disabled]="contactForm.invalid || formProgress">Send Message</button>
            <button type="button" class="btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" stroke="#6859bc" height="16.828"
                viewBox="0 0 18 16.828">
                <g transform="translate(-381 -650.586)">
                  <path d="M-2,0H14" transform="translate(384 659)" fill="none" stroke-linecap="round"
                    stroke-width="2" />
                  <path d="M12,5l7,7-7,7" transform="translate(379 647)" fill="none" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                </g>
              </svg>
            </button>
          </a>
        </form>
      </div>
      <div class="col-md-6 my-2 pr-md-4">
        <!--<img class="img-fluid" src="../../../../assets/images/graphics/Map_view.png" alt="">-->
        <div class="map-wrapper">
          <div class="map-container">
            
            <iframe width="100%" height="100%" id="gmap_canvas"
              src="https://maps.google.com/maps?q=41/75 Thaper Colony Jagadhri, 135001&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2yu.co"></a><br>
              <div class="map-overlay"></div>
            <style>
              .map-container {
                position: relative;
                width: 100%;
                height: 510px;
                overflow: hidden;
                border-radius: 20px; /* Adding border radius */

              }
              .map-wrapper {
        border-radius: 20px; /* Adding border radius */
        overflow: hidden; /* Hide overflow to make border radius visible */
    }

              .mapouter {
                position: relative;
                text-align: right;
                height: 100%;
                width: 100%;
              }

              .map-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 50, 0.679);
                border-radius: 20px; /* Adding border radius */

                /* Dark blue color with 50% opacity */
                pointer-events: none;
                /* Allows clicks to go through the overlay to the map */
              }
              @media screen and (max-width: 776px) {
                .map-container {
                position: relative;
                width: 100%;
                height: 400px !important;
                overflow: hidden;
                border-radius: 20px; /* Adding border radius */

              }
                .map-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 50, 0.679);
                border-radius: 20px; /* Adding border radius */

                /* Dark blue color with 50% opacity */
                pointer-events: none;
                /* Allows clicks to go through the overlay to the map */
              }
        .map-container {
          height: 100%;
        }
    }
            </style><a href="https://embedgooglemap.2yu.co"></a>
            <style>
              .gmap_canvas {
                overflow: hidden;
                background: none !important;
                height: 100%;
                width: 100%;
              }
            </style>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>