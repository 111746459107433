import { Component, OnInit } from '@angular/core';
import {

  ViewChild,
  ChangeDetectionStrategy,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.css']
})
export class ConnectComponent implements OnInit {
  @ViewChild('slickModal') carouselTestimonial!: SlickCarouselComponent;
  @ViewChild('carouselTestimonialImage')
  carouselTestimonialImage!: SlickCarouselComponent;
  isLargeCompany = false;
  contactForm: any;
  formProgress = false;
  navWorkActive = 0;

  readMore = {
    intro: false,
    growth: false,
    team: false,
    excellence: false,
  };

  slideConfig1 = {
    infinite: false,
    dots: false,
    prevArrow: false,
    nextArrow: false,
    centerMode: true,
    centerPadding: '70px',
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '70px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        },
      },
    ],
  };
  slickCurrent = 0;
  slickSlides: any[] = [];
  webSlides: any[] = [];
  mobileSlides: any[] = [];
  brandSlides: any[] = [];
  codeSlides: any[] = [];
  testimonials: any[] = [];
  features: any = [
    {
      category: 'Customized Solutions',
      content:
        "We believe in tailoring solutions to meet your specific needs. Whether it's a simple yet elegant website or a complex web application, our team crafts bespoke solutions aligned with your goals.",
    },
    {
      category: 'Responsive Design',
      content:
        'With the increasing diversity of devices used to access the web, responsive design is imperative. We prioritize creating websites that adapt seamlessly across various screen sizes, ensuring an optimal user experience on desktops, tablets, and mobile devices.',
    },
    {
      category: 'Frontend & Backend Excellence',
      content:
        'Our expertise spans both frontend and backend development. We excel in crafting visually appealing interfaces using HTML, CSS, and JavaScript while also building robust backend functionalities using frameworks like Node.js, Django, or Laravel.',
    },
    {
      category: 'Continuous Support & Maintenance',
      content:
        "Our commitment doesn't end at deployment. We offer ongoing support and maintenance services, ensuring your website remains secure, up-to-date, and equipped to adapt to evolving user needs and technological advancements.",
    },
    {
      category:"SEO & Performance Optimization",
      content:"Building a stunning website is only half the battle; ensuring its visibility and performance is equally crucial. We incorporate SEO best practices and optimize website performance to enhance search engine rankings and deliver lightning-fast experiences."
    }
   
  ];



  options: any = [
    {
      imagePath: '../../../../assets/images/clients/angular.png',
      category: 'Angular JS',
      content:
        'With the right combination of software, drivers, compute, memory, network and storage resources, we provide you with scalable deep learning solutions.',
      class: 'custom-light-purple',
    },
    {
      imagePath: '../../../../assets/images/clients/react.svg',
      category: 'React JS',
      content:
      'With the right combination of software, drivers, compute, memory, network and storage resources, we provide you with scalable deep learning solutions.',
      class: 'custom-light-light',
    },
    {
      imagePath: '../../../../assets/images/clients/vue.png',
      category: 'Vue JS',
      content:
      'With the right combination of software, drivers, compute, memory, network and storage resources, we provide you with scalable deep learning solutions.',
      class: 'custom-light-green',
    },
    {
      imagePath: '../../../../assets/images/clients/js.png',
      category: 'Node JS',
      content:
        'With the right combination of software, drivers, compute, memory, network and storage resources, we provide you with scalable deep learning solutions.',
      class: 'custom-light-blue',
    },
    {
      imagePath: '../../../../assets/images/clients/laravel.svg',
      category: 'Laravel',
      content:
        'With the right combination of software, drivers, compute, memory, network and storage resources, we provide you with scalable deep learning solutions.',
      class: 'custom-light-red',
    }
  ];
  constructor(  private formBuilder: FormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2) { }

    ngOnInit() {
      this.contactForm = this.formBuilder.group({
        name: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ],
        ],
        email: [
          '',
          [
            Validators.required,
            Validators.email,
            Validators.minLength(3),
            Validators.maxLength(320),
          ],
        ],
        phone: [
          '',
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern('[0-9]*'),
          ],
        ],
        message: ['', [Validators.required, Validators.minLength(20)]],
      });
  
    
    }
  submitContactForm() {
    const that = this;
    that.formProgress = true;
    const formData = this.contactForm.value;
    const postData = {
      type: 'contact',
      isLargeCompany: this.isLargeCompany,
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      message: formData.message,
    };
    that.snackBar.open('Submitting data, Please wait.', 'close', {
      duration: 2000,
    });
    this.http
      .post('https://thecodefusion.com/api/submit.php', postData)
      .subscribe(
        (res) => {
          that.formProgress = false;
          that.snackBar.open(
            'Thanks, for submitting form. Our team will get in touch with you.',
            'Ok',
            {
              duration: 2000,
            }
          );
          that.contactForm.reset();
        },
        (err) => {
          that.snackBar.open('error.', 'Ok', {
            duration: 2000,
          });
          that.formProgress = false;
        }
      );
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.contactForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  slickInit(e, slick) {
    this.slickCurrent = slick.currentIndex;
  }
  afterChange(e, slick) {
    this.slickCurrent = slick.currentIndex;
  }
  afterChange2(e, slick) {
    this.carouselTestimonialImage.slickGoTo(slick.currentIndex);
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.handleNavigation();
      });
    // setTimeout(() => {
    //   this.handleNavigation();
    // }, 1000)
  }

  handleNavigation() {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const targetElement = document.getElementById(fragment);
        if (targetElement) {
          const scrollY = fragment === 'about-us' ? 500 : 0
          this.scrollToFragment(targetElement, scrollY);
        } else {
          // If the section doesn't exist, you can handle it here (e.g., navigate to a default section)
        }
      } else {
        this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
        this.renderer.setProperty(document.body, 'scrollTop', 0);
      }
    });
  }

  scrollToFragment(element: HTMLElement, yOffset = 0) {
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
    this.renderer.setProperty(document.documentElement, 'scrollTop', y);
  }

}
