<div class="container-fluid gray-3 px-md-5 pb-md-5 pt-0 " data-aos="fade-up">
    <div class="row px-md-5  mx-0  pb-md-3 gray-star-6">
        <div class="col-md-6 col-12 d-flex justify-content-center" data-aos="fade-up">
            <img src="
            ../../../../../../assets/images/clients/our-team-start.svg" alt="team" class="home-screen">
        </div>
        <div class="col-md-6 col-12 d-flex flex-column justify-content-center" data-aos="fade-up">
            <p class="font-24 ">Meet the driving force behind Code Fusion – a group of dynamic individuals united by a
                shared passion for innovation and a commitment to shaping the digital landscape.
            </p>

        </div>

    </div>
</div>

<div class="container-fluid  px-md-5 pb-md-5 pt-0 mb-md-5 " data-aos="fade-up ">
    <p class="font-36  gradient-font text-center font-weight-600 py-md-5 py-3">OUR TEAM
    <div class="row w-75 m-auto gray-star-4 px-md-5  mx-0  pb-md-3 ">

        <div class="col-md-3 mb-md-0 mb-5 pb-md-0 pb-5"  *ngFor="let item of members" data-aos="fade-up" >
            <div>
                <div>

                    <img src="../../../../assets/images/clients/team.png" alt="" class="w-100">
                </div>
                <div class="d-flex justify-content-center">
                    <button class="name-btn p-2 font-weight-500 font-16">john</button>
                </div>
            </div>



        </div>

    </div>
</div>






<div class="container-fluid px-md-5 pt-5 px-2 gradient-gray " data-aos="fade-up">
   
    <p class="font-36  text-dark text-center">About our team</p>
    <div class="row px-md-5 px-3 pt-md-5 pt-3 bottom-shape ">
        <div class="col-md-6 custom-border py-md-5 py-3" *ngFor="let item of stands" data-aos="fade-up">
            <div class="d-md-flex ">
                <div ><img [src]="item.image" alt="" class="icons p-md-2 p-2"></div>
                <div class="px-4">
                    <p class="color-purple font-21">{{item.heading}}</p>
                    <p class="color-black font-16 line-height">{{item.content}}
                    </p>
                </div>
            </div>
           
        </div>
    </div>
</div>














<div class="container-fluid  p-md-5 ">
    <div class="row px-md-5 gray-3 rounded  mx-md-5 py-5">

        <div class="col-md-6 col-12 d-flex flex-column justify-content-center" data-aos="fade-up">

            
            <p class="line-height">Individually, we bring diverse skills and perspectives; together, we form a cohesive unit dedicated to pushing boundaries and exceeding expectations. </p>
            <p class="line-height">At Code Fusion, our team isn't just a workforce – it's a family driven by a shared mission: to redefine web development and leave an indelible mark on the digital landscape. </p>
            <p>Join us in this exciting journey where every member is not just an employee but a crucial contributor to our collective success. </p>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-center" data-aos="fade-up">
            <img src="
            ../../../../../../assets/images/clients/our.png" alt="home" class="our-team home-screen" >
        </div>
    </div>
</div>



