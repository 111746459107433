import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThemeModule } from './views/theme/theme.module';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AboutUsComponent } from './views/pages/about-us/about-us.component';
import { OurTeamsComponent } from './views/pages/our-teams/our-teams.component';
import { CaseStudyComponent } from './views/pages/case-study/case-study.component';
import { ConnectComponent } from './views/pages/connect/connect.component';
import { HomeRedesignComponent } from './views/pages/home-redesign/home-redesign.component';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { OurWorkComponent } from './views/pages/our-work/our-work.component';


@NgModule({
  declarations: [AppComponent, AboutUsComponent, OurTeamsComponent, CaseStudyComponent, ConnectComponent, HomeRedesignComponent, OurWorkComponent],
  imports: [BrowserModule,
     AppRoutingModule, ThemeModule, NoopAnimationsModule, CommonModule, SlickCarouselModule, BrowserAnimationsModule,
 
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
