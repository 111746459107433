<div class="cd-overlay-nav" [class.active]="isNavOverlayVisible">
  <span class=""></span>
</div>
<nav class="'/' cd-navbar-wrap" [class.active]="isNavVisible">
  <img src="assets/images/services/service-2-bg-2.svg" class="cd-navbar-bg" style="">
  <div style="overflow-y: auto; overflow-x: hidden; position: absolute; top: 0; bottom: 0; left: 0; right: 0;">
    <div class="container-fluid">
      <div class="row" style="padding-top: 2rem;">
        <div class="col-12 col-md order-last order-md-first">
          <img style="height: 80px;" src="assets/images/logo/code-fusion-icon.svg" alt=""><span
            class="ml-2 text-white pr-4"
            style="border-bottom: 1px solid #fff; font-weight: 20px; font-weight: 600;">MENU</span>
        </div>
        <div class="col-12 col-md header-social text-white" style="padding-right: 6rem; text-align: right;">
          <a href="https://www.facebook.com/thecodefusion">
            <img src="assets/images/icons/facebook.svg">
          </a>
          <a href="https://twitter.com/The_CodeFusion">
            <img src="assets/images/icons/twitter.svg">
          </a>
          <a href="https://www.instagram.com/thecodefusion/">
            <img src="assets/images/icons/instagram.svg">
          </a>
          <a href="https://www.linkedin.com/company/code-fusion-private-limited/">
            <img src="assets/images/icons/linkedin.svg">
          </a>
        </div>
      </div>
    </div>
    <div class="container-menu"> 
      <div class="row py-fhd-4">
        <div class="col-md-6">
          <ul class="cd-navbar pl-0 pt-3 pt-md-2">
            <a class="cd-nav-item" [class.active]="activeMenu === 'home'" (click)="openMenu(); activeMenu = 'home'"
              href="home#">Home</a>
            <a class="cd-nav-item" [class.active]="activeMenu === 'work'"
              (click)="openMenu(); activeMenu = 'Work Force Solution'" href="home#work">Work Force solutions</a>
            <a class="cd-nav-item" [class.active]="activeMenu === 'about'" (click)="openMenu(); activeMenu = 'about'"
              href="home#about-us"><del>About Us</del></a>
            <a class="cd-nav-item" [class.active]="activeMenu === 'services'"
              (click)="openMenu(); activeMenu = 'services'" href="home#services">Services</a>
            <a class="cd-nav-item " [class.active]="activeMenu === 'expertise'"
              (click)="openMenu(); activeMenu = 'expertise'" href="home#expertise">Expertise </a>



            <a class="cd-nav-item" [class.active]="activeMenu === 'contact'"
              (click)="openMenu(); activeMenu = 'contact'" href="home#contact-us">Contact Us</a>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="header-contact">
            <h5 class="font-weight-semibold py-4 pl-1 text-white">Contact Us</h5>
            <ul style="list-style: none;">
              <li>41/75 Thaper Colony<br>Jagadhri, 135001</li>
              <li>
                +91 8837624775 &nbsp;&nbsp;<img src="assets/images/icons/phone-call.svg" alt=""><br>
                +91 7709727784&nbsp;&nbsp;<img src="assets/images/icons/phone-call.svg" alt="">
              </li>
              <li>
                hi@thecodefusion.com&nbsp;&nbsp;<img src="assets/images/icons/mail.svg" alt=""><br>
                shivam@thecodefusion.com&nbsp;&nbsp;<img src="assets/images/icons/mail.svg" alt="">
              </li>
            </ul>
            <div class="text-center text-md-right">
              <a class="btn-group btn-split mt-2" href="home#contact-us" (click)="openMenu(); " role="group"
                aria-label="Basic example">
                <button type="button" class="btn">View Map</button>
                <button type="button" class="btn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" stroke="#6859bc" height="16.828"
                    viewBox="0 0 18 16.828">
                    <g transform="translate(-381 -650.586)">
                      <path d="M-2,0H14" transform="translate(384 659)" fill="none" stroke-linecap="round"
                        stroke-width="2" />
                      <path d="M12,5l7,7-7,7" transform="translate(379 647)" fill="none" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" />
                    </g>
                  </svg>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid fixed-bottom-xxl">
      <div class="row justify-content-center" style="height: 58px; margin-bottom: 15px; margin-top: 30px;">
        <div class="col-auto">
          <span style="width: 67px; display: inline-block;"><img src="assets/images/icons/quote.png" alt=""></span>
        </div>
        <div class="col-auto" style="padding-top: 7px; padding-left: 0;">
          <span style="font-size: 20PX; line-height: 1; color:white; font-weight: 600;">DAILY<br>QUOTES</span>
        </div>
        <div class="col-12 col-md py-2 py-md-0">
          <h5 style="color: rgba(255, 255, 255, 0.8);top: 50%;position: relative;transform: translateY(-50%);"
            class="font-weight-bold text-center text-md-right">“We don't just build websites, we build websites that
            SELLS” - Christopher Dayagdag
          </h5>
        </div>
      </div>
    </div>
  </div>
</nav>
<div class="cd-overlay-content" [class.active]="isContentOverlayVisible">
  <span class=""></span>
</div>









<!-- new nav -->












<!--<button (click)='menuShow = !menuShow' class="navbar-toggler" type="button" data-toggle="collapse"
        data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>-->



<section [ngClass]="{'header-2 custom-gradient': !showheader }">
  <div class="section-inner bg-gradient-primary"></div>
  <header class="nav-header" [class.header-fixed]='isHeaderSticky'
    [ngClass]="isHeaderSticky ? 'navbar-light' : 'navbar-dark'">
    <div class="scroll-progress" [style.width]="scrollProgress+'%'"></div>
    <div class="container-fluid bg-purple">
      <div class="row " >
        <div class="col-lg-11 col-md-12 col-12 d-flex  justify-content-between m-auto pt-md-4 py-2" style="max-width: 1440px;">
        
            <div>
              <a class="navbar-brand" href="#">
                <img style="height: 50px; padding: 3px 0;"
                  [src]="isHeaderSticky ? '/assets/images/logo/code-fusion-light.png' : '/assets/images/logo/code-fusion-light.png'"></a>
            </div>
            <div class="d-md-block d-flex align-items-center">
              <div class="d-xl-block d-none">
                <div class="d-md-flex">
                  <div class="d-flex justify-content-evenly flex-column">
    
    
                    <div class="d-md-flex justify-content-end text-white font-12 mb-2">
                      <div class="font-weight-600  ">
                        <img src="../../assets/images/clients/mail.svg" alt="mail" class="me-2 icons">
                        <a href="mailto:hi@thecodefusion.com" class="text-white">hi@thecodefusion.com</a>
                      </div>
                      <div class="line mx-3">
    
                      </div>
                      <div class="font-weight-600">
                        <img src="../../assets/images/clients/mobile.svg" alt="mobile" class="me-2 icons">
                        <a href="tel:+918837624775" class="text-white">+91 8837624775</a>,
                        <a href="tel:+917709727784" class="text-white">+91 7709727784</a>


                      </div>
                    </div>
                    <div class="text-white font-14 font-weight-500">
                      <div class="d-md-flex">
                        <a class="nav-item nav-link" [class.active]="activeMenu === 'home'" (click)="activeMenu = 'home'"
                          routerLink="/home">Home</a>
                        <a class="nav-item nav-link" [class.active]="activeMenu === 'work'" (click)="activeMenu = 'work'"
                          routerLink="/home" fragment="work">WorkForce solutions</a>
    
                        <!-- Nav Item with Dropdown -->
                        <div class="dropdown" (mouseover)="aboutUs = true" (mouseout)="aboutUs = false"
                          (click)="activeMenu = 'aboutUs'">
                          <a class="nav-item nav-link" [routerLink]="'/about-us'" routerLinkActive="active">About Us <img
                              src="../../assets/images/clients/bottom-white-arrow.svg" alt="arrow" class="ms-1"></a>
    
                          <div class="dropdown-menu p-3 bottom-shadow shadow border-0" aria-labelledby="navbarDropdown"
                            [class.show]="aboutUs">
                            <!-- Dropdown links -->
                            <a class="dropdown-item" [routerLink]="'/our-teams'" routerLinkActive="active">Our teams
                            </a>
                            <a class="dropdown-item" [routerLink]="'/careers'" routerLinkActive="active">
                              We are hiring</a>
                            <!-- Add more dropdown items as needed -->
                          </div>
                        </div>
    
    
                        <!--  -->
    
    
                        <div class="dropdown cursor-pointer" (mouseover)="services = true" (mouseout)="services = false"
                          (click)="activeMenu = 'services'" >
                          <a class="nav-item nav-link" [class.active]="activeMenu === 'services'"
                            (click)="activeMenu = 'services'" fragment="services">Services <img
                              src="../../assets/images/clients/bottom-white-arrow.svg" alt="arrow" class="ms-1"></a>
                          <div class="dropdown-menu p-3 bottom-shadow shadow border-0" aria-labelledby="navbarDropdown"
                            [class.show]="services" data-aos="fade-up">
                            <!-- Dropdown links -->
                            <a class="dropdown-item" [routerLink]="'/web-development'" routerLinkActive="active">Web
                              Development
    
                            </a>
                            <a class="dropdown-item " [routerLink]="'/app-development'" routerLinkActive="active">Android
                              Development
    
    
                            </a>
                            <a class="dropdown-item " [routerLink]="'/app-development'" routerLinkActive="active">IOS
                              Development
    
    
                            </a>
                            <a class="dropdown-item disabled" [routerLink]="'/seo'" routerLinkActive="active">SEO Services
    
                            </a>
                            <!-- Add more dropdown items as needed -->
                          </div>
                        </div>
    
    
    
    
    
    
                        <div class="dropdown" (mouseover)="expertiseOpened = true" (mouseout)="expertiseOpened = false"
                          (click)="activeMenu = 'expertiseOpened'">
                          <a class="nav-item nav-link" [class.active]="activeMenu === 'expertise'"
                            (click)="activeMenu = 'expertise'" [routerLink]="'/expertise'">Expertise </a>
    
                        </div>
    
    
    
                        <!-- <a class="nav-item nav-link " [routerLink]="'/expertise'" [class.active]="activeMenu === 'expertise'"
                        (click)="activeMenu = 'expertise'" href="home#expertise">Expertise</a> -->
                        <!--         
                        <div class="dropdown expertise-custom" (mouseover)="expertiseOpened = true" (mouseout)="expertiseOpened = false" (click)="activeMenu = 'expertise'">
                          
                          <a class="nav-item nav-link" [routerLink]="'/expertise'"  [class.active2]="activeMenu === 'expertise'" href="expertise" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
                            Expertise  <img src="../../assets/images/clients/bottom-white-arrow.svg" alt="arrow" class="ms-1">
                          </a>
                          <div class="dropdown-menu rounded-20 shadow" [class.show]="expertiseOpened" aria-labelledby="navbarDropdown">
                            <div class="container-fluid mt-3">
                              <div class="row">
                                  <div class="col rounded-20">
                                      <div class="">
                                          <div class=" d-flex justify-content-center pt-4 ">
                                  
                                              <span class="bg-custom-secondary p-2 switch-btn">
                                                  <button [ngClass]="{'default': show, 'active-btn': !show}" (click)="switchTech(false)">Technology</button>
                                                  <button [ngClass]="{'default': !show, 'active-btn': show}" (click)="switchTech(true)">Industry</button>
                                              </span>
                                          </div>
                                          <div class="d-flex justify-content-center pt-4 pb-4" *ngIf="show == false">
                                              <div class="container justify-content-center my-4 pb-4">
                                                  <div class="row m-auto">
                                                  
                                  
                                                      <div class="col-6" *ngFor="let option of options; let i = index">
                                                        <div class="mb-4 d-flex align-items-center cursor-pointer"
                                                            *ngIf="option.category !== 'Metaverse & AR/VR'; else disabledClick"
                                                            (click)="navigateToChatbotDevelopment(option.path)">
                                                          <div [class]="option.backgroundColor + ' p-2 rounded-circle me-3 custom-h-w'">
                                                            <img [src]="option.imagePath" alt="">
                                                          </div>
                                                          <div>{{option.category}}</div>
                                                        </div>
                                                        <ng-template #disabledClick>
                                                          <div class="mb-4 d-flex align-items-center" style="opacity: 0.5; cursor: not-allowed;">
                                                            <div [class]="option.backgroundColor + ' p-2 rounded-circle me-3 custom-h-w'">
                                                              <img [src]="option.imagePath" alt="">
                                                            </div>
                                                            <div>{{option.category}}</div>
                                                          </div>
                                                        </ng-template>
                                                      </div>
                                                
                                                  </div>
                                              </div>
                                  
                                          </div>
                                          <div class="d-flex justify-content-center pt-4 pb-4" *ngIf="show == true">
                                              <div class="container justify-content-center my-4 pb-4">
                                                  <div class="row m-auto">
                                                      <div class="col-6" *ngFor="let option of industry; let i = index">
                                  
                                                          <div class="mb-4 d-flex align-items-center cursor-pointer"
                                                              (click)="navigateToChatbotDevelopment(option.path)">
                                                              <div [class]="option.backgroundColor + '  p-2 rounded-circle me-3 custom-h-w'">
                                                                  <img src={{option.imagePath}} alt="">
                                                              </div>
                                                              <div>{{option.category}}</div>
                                                          </div>
                                                      
                                                      </div>
                                  
                                                
                                                
                                                  </div>
                                              </div>
                                  
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            
                          </div>
                          
                          
                          
                          </div>
                        </div> -->
    
    
    
    
    
    
                        <div class="dropdown cursor-pointer" (mouseover)="technology = true" (mouseout)="technology = false"
                          (click)="activeMenu = 'technology'" >
                          <a class="nav-item nav-link" routerLinkActive="active">Technology <img
                              src="../../assets/images/clients/bottom-white-arrow.svg" alt="arrow" class="ms-1"></a>
                          <div class="dropdown-menu p-3 bottom-shadow shadow border-0" aria-labelledby="navbarDropdown"
                            [class.show]="technology" >
                            <!-- Dropdown links -->
                            <a class="dropdown-item" [routerLink]="'/expertise/chatbot-development'"
                              routerLinkActive="active">Chatbot Development
    
    
                            </a>
                            <a class="dropdown-item" [routerLink]="'/expertise/machine-learning'"
                              routerLinkActive="active">Machine Learning
    
    
    
                            </a>
                            <a class="dropdown-item" [routerLink]="'/expertise/progressive-web-apps'"
                              routerLinkActive="active">Progressive Web Apps
    
    
    
                            </a>
                            <a class="dropdown-item" [routerLink]="'/expertise/blockchain-development'"
                              routerLinkActive="active">Blockchain Development
    
    
                            </a>
                            <a class="dropdown-item" [routerLink]="'/expertise/cloud-computing'"
                              routerLinkActive="active">Cloud Computing
    
    
                            </a>
                            <!-- Add more dropdown items as needed -->
                          </div>
                        </div>
    
    
    
    
    
                        <div class="dropdown cursor-pointer" (mouseover)="industryOpened = true"
                          (mouseout)="industryOpened = false" (click)="activeMenu = 'industryOpened'">
                          <a class="nav-item nav-link" routerLinkActive="active">Industry <img
                              src="../../assets/images/clients/bottom-white-arrow.svg" alt="arrow" class="ms-1"></a>
                          <div class="dropdown-menu p-3 bottom-shadow shadow border-0" aria-labelledby="navbarDropdown"
                            [class.show]="industryOpened" >
                            <!-- Dropdown links -->
                            <a class="dropdown-item" [routerLink]="'/expertise/education-elearning'"
                              routerLinkActive="active">Education & eLearning
    
    
    
                            </a>
                            <a class="dropdown-item" [routerLink]="'/expertise/automobile-logistics'"
                              routerLinkActive="active">Automobile & Logistics
    
    
    
    
                            </a>
                            <a class="dropdown-item" [routerLink]="'/expertise/retail-eCommerce'"
                              routerLinkActive="active">Retail & eCommerce
    
    
    
                            </a>
                            <a class="dropdown-item" [routerLink]="'/expertise/pharma-healthcare'"
                              routerLinkActive="active">Pharma & Healthcare
    
    
                            </a>
    
                            <!-- Add more dropdown items as needed -->
                          </div>
                        </div>
    
    
                        <a class="nav-item nav-link" [routerLink]="'/contact-us'" routerLinkActive="active">Contact Us</a>
    
                        <!-- <a class="nav-item nav-link" [class.active]="activeMenu === 'contact-us'" (click)="activeMenu = 'contact-us'"
                        routerLink="/home" fragment="contact-us">Contact Us</a> -->
                      </div>
                    </div>
                  </div>
                  <div class="d-md-flex d-none align-items-center ">
                    <a class="quote ms-4">Get a free quote</a>
                  </div>
                </div>
              </div>
              <div class="d-xl-none d-block">
              
                <nav class="navbar navbar-expand-xl ">
                  <div class="d-xl-none">
                      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                          <span class="navbar-toggler-icon"></span>
                      </button>
                  </div>
                  
                
              </nav>
              
              </div>
            </div>
     
          
        </div>
       
      </div>
      <div class="row d-xl-none d-block">
        <div class="collapse navbar-collapse" id="navbarNav">
          <div class="">
            <div class="d-flex justify-content-evenly flex-column">
              <div class="d-md-flex justify-content-end text-white font-12 mb-2">
                <div class="font-weight-600  ">
                  <img src="../../assets/images/clients/mail.svg" alt="mail" class="me-2 icons">
                  hi@thecodefusion.com
                </div>
                <div class="line mx-3">

                </div>
                <div class="font-weight-600">
                  <img src="../../assets/images/clients/mobile.svg" alt="mobile" class="me-2 icons">
                  +91 8837624775, +91 7709727784
                </div>
              </div>
              <div class="text-white font-14 font-weight-500">
                <div class="d-md-flex justify-content-evenly">
                  <a class="nav-item nav-link" [class.active]="activeMenu === 'home'" (click)="activeMenu = 'home'"
                    routerLink="/home">Home</a>
                  <a class="nav-item nav-link" [class.active]="activeMenu === 'work'" (click)="activeMenu = 'work'"
                    routerLink="/home" fragment="work">WorkForce solutions</a>

                  <!-- Nav Item with Dropdown -->
                  <div class="dropdown" (mouseover)="aboutUs = true" (mouseout)="aboutUs = false"
                    (click)="activeMenu = 'aboutUs'">
                    <a class="nav-item nav-link" [routerLink]="'/about-us'" routerLinkActive="active">About Us <img
                        src="../../assets/images/clients/bottom-white-arrow.svg" alt="arrow" class="ms-1"></a>

                    <div class="dropdown-menu p-3 bottom-shadow shadow border-0" aria-labelledby="navbarDropdown"
                      [class.show]="aboutUs">
                      <!-- Dropdown links -->
                      <a class="dropdown-item" [routerLink]="'/our-teams'" routerLinkActive="active">Our teams
                      </a>
                      <a class="dropdown-item" [routerLink]="'/careers'" routerLinkActive="active">
                        We are hiring</a>
                      <!-- Add more dropdown items as needed -->
                    </div>
                  </div>


                  <!--  -->


                  <div class="dropdown cursor-pointer" (mouseover)="services = true" (mouseout)="services = false"
                    (click)="activeMenu = 'services'">
                    <a class="nav-item nav-link" [class.active]="activeMenu === 'services'"
                      (click)="activeMenu = 'services'" fragment="services">Services <img
                        src="../../assets/images/clients/bottom-white-arrow.svg" alt="arrow" class="ms-1"></a>
                    <div class="dropdown-menu p-3 bottom-shadow shadow border-0" aria-labelledby="navbarDropdown"
                      [class.show]="services">
                      <!-- Dropdown links -->
                      <a class="dropdown-item" [routerLink]="'/web-development'" routerLinkActive="active">Web
                        Development

                      </a>
                      <a class="dropdown-item " [routerLink]="'/app-development'" routerLinkActive="active">Android
                        Development


                      </a>
                      <a class="dropdown-item " [routerLink]="'/app-development'" routerLinkActive="active">IOS
                        Development


                      </a>
                      <a class="dropdown-item disabled" [routerLink]="'/seo'" routerLinkActive="active">SEO Services

                      </a>
                      <!-- Add more dropdown items as needed -->
                    </div>
                  </div>






                  <div class="dropdown" (mouseover)="expertiseOpened = true" (mouseout)="expertiseOpened = false"
                    (click)="activeMenu = 'expertiseOpened'">
                    <a class="nav-item nav-link" [class.active]="activeMenu === 'expertise'"
                      (click)="activeMenu = 'expertise'" [routerLink]="'/expertise'">Expertise </a>

                  </div>



                  <!-- <a class="nav-item nav-link " [routerLink]="'/expertise'" [class.active]="activeMenu === 'expertise'"
                  (click)="activeMenu = 'expertise'" href="home#expertise">Expertise</a> -->
                  <!--         
                  <div class="dropdown expertise-custom" (mouseover)="expertiseOpened = true" (mouseout)="expertiseOpened = false" (click)="activeMenu = 'expertise'">
                    
                    <a class="nav-item nav-link" [routerLink]="'/expertise'"  [class.active2]="activeMenu === 'expertise'" href="expertise" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      Expertise  <img src="../../assets/images/clients/bottom-white-arrow.svg" alt="arrow" class="ms-1">
                    </a>
                    <div class="dropdown-menu rounded-20 shadow" [class.show]="expertiseOpened" aria-labelledby="navbarDropdown">
                      <div class="container-fluid mt-3">
                        <div class="row">
                            <div class="col rounded-20">
                                <div class="">
                                    <div class=" d-flex justify-content-center pt-4 ">
                            
                                        <span class="bg-custom-secondary p-2 switch-btn">
                                            <button [ngClass]="{'default': show, 'active-btn': !show}" (click)="switchTech(false)">Technology</button>
                                            <button [ngClass]="{'default': !show, 'active-btn': show}" (click)="switchTech(true)">Industry</button>
                                        </span>
                                    </div>
                                    <div class="d-flex justify-content-center pt-4 pb-4" *ngIf="show == false">
                                        <div class="container justify-content-center my-4 pb-4">
                                            <div class="row m-auto">
                                            
                            
                                                <div class="col-6" *ngFor="let option of options; let i = index">
                                                  <div class="mb-4 d-flex align-items-center cursor-pointer"
                                                      *ngIf="option.category !== 'Metaverse & AR/VR'; else disabledClick"
                                                      (click)="navigateToChatbotDevelopment(option.path)">
                                                    <div [class]="option.backgroundColor + ' p-2 rounded-circle me-3 custom-h-w'">
                                                      <img [src]="option.imagePath" alt="">
                                                    </div>
                                                    <div>{{option.category}}</div>
                                                  </div>
                                                  <ng-template #disabledClick>
                                                    <div class="mb-4 d-flex align-items-center" style="opacity: 0.5; cursor: not-allowed;">
                                                      <div [class]="option.backgroundColor + ' p-2 rounded-circle me-3 custom-h-w'">
                                                        <img [src]="option.imagePath" alt="">
                                                      </div>
                                                      <div>{{option.category}}</div>
                                                    </div>
                                                  </ng-template>
                                                </div>
                                          
                                            </div>
                                        </div>
                            
                                    </div>
                                    <div class="d-flex justify-content-center pt-4 pb-4" *ngIf="show == true">
                                        <div class="container justify-content-center my-4 pb-4">
                                            <div class="row m-auto">
                                                <div class="col-6" *ngFor="let option of industry; let i = index">
                            
                                                    <div class="mb-4 d-flex align-items-center cursor-pointer"
                                                        (click)="navigateToChatbotDevelopment(option.path)">
                                                        <div [class]="option.backgroundColor + '  p-2 rounded-circle me-3 custom-h-w'">
                                                            <img src={{option.imagePath}} alt="">
                                                        </div>
                                                        <div>{{option.category}}</div>
                                                    </div>
                                                
                                                </div>
                            
                                          
                                          
                                            </div>
                                        </div>
                            
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                    
                    
                    
                    </div>
                  </div> -->






                  <div class="dropdown cursor-pointer" (mouseover)="technology = true" (mouseout)="technology = false"
                    (click)="activeMenu = 'technology'">
                    <a class="nav-item nav-link" routerLinkActive="active">Technology <img
                        src="../../assets/images/clients/bottom-white-arrow.svg" alt="arrow" class="ms-1"></a>
                    <div class="dropdown-menu p-3 bottom-shadow shadow border-0" aria-labelledby="navbarDropdown"
                      [class.show]="technology">
                      <!-- Dropdown links -->
                      <a class="dropdown-item" [routerLink]="'/expertise/chatbot-development'"
                        routerLinkActive="active">Chatbot Development


                      </a>
                      <a class="dropdown-item" [routerLink]="'/expertise/machine-learning'"
                        routerLinkActive="active">Machine Learning



                      </a>
                      <a class="dropdown-item" [routerLink]="'/expertise/progressive-web-apps'"
                        routerLinkActive="active">Progressive Web Apps



                      </a>
                      <a class="dropdown-item" [routerLink]="'/expertise/blockchain-development'"
                        routerLinkActive="active">Blockchain Development


                      </a>
                      <a class="dropdown-item" [routerLink]="'/expertise/cloud-computing'"
                        routerLinkActive="active">Cloud Computing


                      </a>
                      <!-- Add more dropdown items as needed -->
                    </div>
                  </div>





                  <div class="dropdown cursor-pointer" (mouseover)="industryOpened = true"
                    (mouseout)="industryOpened = false" (click)="activeMenu = 'industryOpened'">
                    <a class="nav-item nav-link" routerLinkActive="active">Industry <img
                        src="../../assets/images/clients/bottom-white-arrow.svg" alt="arrow" class="ms-1"></a>
                    <div class="dropdown-menu p-3 bottom-shadow shadow border-0" aria-labelledby="navbarDropdown"
                      [class.show]="industryOpened">
                      <!-- Dropdown links -->
                      <a class="dropdown-item" [routerLink]="'/expertise/education-elearning'"
                        routerLinkActive="active">Education & eLearning



                      </a>
                      <a class="dropdown-item" [routerLink]="'/expertise/automobile-logistics'"
                        routerLinkActive="active">Automobile & Logistics




                      </a>
                      <a class="dropdown-item" [routerLink]="'/expertise/retail-eCommerce'"
                        routerLinkActive="active">Retail & eCommerce



                      </a>
                      <a class="dropdown-item" [routerLink]="'/expertise/pharma-healthcare'"
                        routerLinkActive="active">Pharma & Healthcare


                      </a>  

                      <!-- Add more dropdown items as needed -->
                    </div>
                  </div>


                  <a class="nav-item nav-link" [routerLink]="'/contact-us'" routerLinkActive="active">Contact Us</a>

                  <!-- <a class="nav-item nav-link" [class.active]="activeMenu === 'contact-us'" (click)="activeMenu = 'contact-us'"
                  routerLink="/home" fragment="contact-us">Contact Us</a> -->
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
    <!-- <nav class="navbar navbar-expand-lg" [ngClass]="isHeaderSticky ? 'navbar-light' : 'navbar-dark'">
      <a class="navbar-brand" href="#">
        <img style="height: 50px; padding: 3px 0;"
          [src]="isHeaderSticky ? '/assets/images/logo/code-fusion.png' : '/assets/images/logo/code-fusion-light.png'"></a>
      <span  [ngClass]="isHeaderSticky ? 'navbar-dark' : 'text-white'"  class="nav-phone"><span class="theme-border-y"></span>&nbsp;&nbsp;<img
          src="assets/images/icons/phone-call.svg" alt="">&nbsp; +91 883 762 4775</span>
  
      <div class="collapse navbar-collapse" [ngClass]="{'show' : menuShow}" id="navbarNavAltMarkup">


        <div class="navbar-nav mx-auto">
          <a class="nav-item nav-link" [class.active]="activeMenu === 'home'" (click)="activeMenu = 'home'"
             routerLink="/home" >Home</a>
          <a class="nav-item nav-link" [class.active]="activeMenu === 'work'" (click)="activeMenu = 'work'"
            routerLink="/home" fragment="work">WorkForce solutions</a>
          <a class="nav-item nav-link" [routerLink]="'/about-us'"  routerLinkActive="active">About Us</a>
       
          <a class="nav-item nav-link" [class.active]="activeMenu === 'services'" (click)="activeMenu = 'services'"
             routerLink="/home" fragment="services">Services</a>


            <div class="dropdown expertise-custom" (mouseover)="expertiseOpened = true" (mouseout)="expertiseOpened = false" (click)="activeMenu = 'expertise'">
              
              <a class="nav-item nav-link" [routerLink]="'/expertise'"  [class.active2]="activeMenu === 'expertise'" href="expertise" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
                Expertise
              </a>
              <div class="dropdown-menu rounded-20 shadow" [class.show]="expertiseOpened" aria-labelledby="navbarDropdown">
                <div class="container-fluid mt-3">
                  <div class="row">
                      <div class="col rounded-20">
                          <div class="">
                              <div class=" d-flex justify-content-center pt-4 ">
                      
                                  <span class="bg-custom-secondary p-2 switch-btn">
                                      <button [ngClass]="{'default': show, 'active-btn': !show}" (click)="switchTech(false)">Technology</button>
                                      <button [ngClass]="{'default': !show, 'active-btn': show}" (click)="switchTech(true)">Industry</button>
                                  </span>
                              </div>
                              <div class="d-flex justify-content-center pt-4 pb-4" *ngIf="show == false">
                                  <div class="container justify-content-center my-4 pb-4">
                                      <div class="row m-auto">
                                  
                      
                                          <div class="col-6" *ngFor="let option of options; let i = index">
                                            <div class="mb-4 d-flex align-items-center cursor-pointer"
                                                 *ngIf="option.category !== 'Metaverse & AR/VR'; else disabledClick"
                                                 (click)="navigateToChatbotDevelopment(option.path)">
                                              <div [class]="option.backgroundColor + ' p-2 rounded-circle me-3 custom-h-w'">
                                                <img [src]="option.imagePath" alt="">
                                              </div>
                                              <div>{{option.category}}</div>
                                            </div>
                                            <ng-template #disabledClick>
                                              <div class="mb-4 d-flex align-items-center" style="opacity: 0.5; cursor: not-allowed;">
                                                <div [class]="option.backgroundColor + ' p-2 rounded-circle me-3 custom-h-w'">
                                                  <img [src]="option.imagePath" alt="">
                                                </div>
                                                <div>{{option.category}}</div>
                                              </div>
                                            </ng-template>
                                          </div>
                                     
                                      </div>
                                  </div>
                      
                              </div>
                              <div class="d-flex justify-content-center pt-4 pb-4" *ngIf="show == true">
                                  <div class="container justify-content-center my-4 pb-4">
                                      <div class="row m-auto">
                                          <div class="col-6" *ngFor="let option of industry; let i = index">
                      
                                              <div class="mb-4 d-flex align-items-center cursor-pointer"
                                                  (click)="navigateToChatbotDevelopment(option.path)">
                                                  <div [class]="option.backgroundColor + '  p-2 rounded-circle me-3 custom-h-w'">
                                                      <img src={{option.imagePath}} alt="">
                                                  </div>
                                                  <div>{{option.category}}</div>
                                              </div>
                                           
                                          </div>
                      
                                     
                                     
                                      </div>
                                  </div>
                      
                              </div>
                          </div>
                      </div>
                  </div>
                 
              </div>
              
              
              
              </div>
            </div>
            
            
            
            
            
            <a class="nav-item nav-link" [routerLink]="'/contact-us'"  routerLinkActive="active">Contact Us</a>

        
        </div>
        <a class="btn btn-outline-secondary btn-menu-right" (click)="activeMenu = 'contact'"  href="home#contact-us" >Get
          a free
          quote</a>
      </div>
      <button class="btn btn-menu-wrap" [class.active]="isNavOverlayVisible" (click)="openMenu()">
        <div class="btn-menu" [class.change]="isMenuOpen">
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
      </button>
    </nav> -->
  </header>
  <!-- <div class="container-fluid" [ngClass]="{'d-none': !showheader}" >
    <div class="row py-5">
      <div class="col-md-6 order-md-last">
        <svg version="1.1" id="heroSVG" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 872.81 718.19" enable-background="new 0 0 872.81 718.19" xml:space="preserve">
          <g id="bg">
            <circle id="Oval" opacity="0.084" fill="#FFFFFF" enable-background="new    " cx="279.97" cy="172.98"
              r="164.95" />
            <circle id="Oval_Copy_21" opacity="0.385" fill="#E8D7FF" enable-background="new    " cx="785.09" cy="310.35"
              r="12.08" />
          </g>
          <g id="restThing">
            <g>
              <path id="pathMoveCrcl2-3" fill="none" stroke="#6658B3" stroke-width="2" stroke-miterlimit="10"
                d="M184.24,513.61
           c-28.98,78,71.03,145.45,137.25,22.19s-16.12-198.07,204.19-220.77S792.37,104.65,722.89,72.75s-71.2,75.62-179.79,46.11" />
              <circle id="crcl2" fill="#E8D7FF" cx="180.99" cy="560.39" r="4.87" />
              <circle id="crcl3" fill="#E8D7FF" cx="337.47" cy="499.63" r="5.77" />
            </g>
            <g>
              <path id="pathMoveCrcl4-5" fill="none" stroke="#6658B3" stroke-width="2" stroke-miterlimit="10" d="M225.64,481.73
           c-24.32,73.66,70.11,197.82,215,4.31C553.2,335.72,500.8,245.89,447,166.48c-15.46-22.82-34.15-39.53-42.84-67.06
           c-30.84-97.74,116-50.5,106.01-11.05S381.77,66.76,464.03,6.16" />
              <circle id="crcl5" fill="#E8D7FF" cx="337.11" cy="580.94" r="8.47" />
              <circle id="crcl4" fill="#E8D7FF" cx="469.8" cy="94.2" r="5.77" />
            </g>
            <path id="Rectangle" fill="#887BCE" d="M179.36,130.25h504.61c4.97,0,9,4.03,9,9v306.13c0,4.97-4.03,9-9,9H179.36
         c-4.97,0-9-4.03-9-9V139.25C170.36,134.28,174.39,130.25,179.36,130.25z" />
            <path id="Rectangle-2" opacity="0.155" fill="#FFFFFF" enable-background="new    " d="M174.36,130.25h80.91l0,0v324.13l0,0h-75.91
         c-4.97,0-9-4.03-9-9V134.25C170.36,132.04,172.15,130.25,174.36,130.25z" />
            <circle id="Oval-2" opacity="0.396" fill="#FFFFFF" enable-background="new    " cx="188.3" cy="148.37"
              r="4.06" />
            <circle id="Oval_Copy" opacity="0.396" fill="#FFFFFF" enable-background="new    " cx="203.62" cy="148.37"
              r="4.06" />
            <circle id="Oval_Copy_2" opacity="0.396" fill="#FFFFFF" enable-background="new    " cx="218.94" cy="148.37"
              r="4.06" />
            <path id="Rectangle-3" opacity="0.173" fill="#FFFFFF" enable-background="new    "
              d="M290.36,152.43h347.33c7.73,0,14,6.27,14,14
         v111.89c0,7.73-6.27,14-14,14H290.36c-7.73,0-14-6.27-14-14V166.43C276.36,158.69,282.63,152.43,290.36,152.43z" />
            <path id="Rectangle-4" opacity="0.13" fill="#FFFFFF" enable-background="new    " d="M308.06,184.15h91.64c3.87,0,7,3.13,7,7
         v63.52c0,3.87-3.13,7-7,7h-91.64c-3.87,0-7-3.13-7-7v-63.52C301.06,187.29,304.19,184.15,308.06,184.15z" />
            <circle id="circlePulse" opacity="0.726" fill="#FFFFFF" enable-background="new    " cx="331.7" cy="214.44"
              r="18.03" />
            <path id="heart" opacity="0.339" fill="#FFFFFF" enable-background="new    " d="M524.15,323.05c-1.56-1.55-4.08-1.55-5.64,0
         l-0.77,0.76l-0.77-0.76c-1.52-1.59-4.04-1.64-5.62-0.13c-1.59,1.52-1.64,4.04-0.13,5.62c0.04,0.04,0.08,0.08,0.12,0.12l0.77,0.76
         l5.64,5.62l5.64-5.62l0.77-0.76c1.55-1.54,1.56-4.05,0.02-5.6C524.16,323.06,524.16,323.05,524.15,323.05z" />
            <g id="Group_6" transform="translate(465.591 159.785)">
              <g id="statsItem">
                <path id="Rectangle-8" opacity="0.805" fill="#F9949A" enable-background="new    " d="M15.07,80.63h20.14c1.66,0,3,1.34,3,3
             v27.53c0,1.66-1.34,3-3,3H15.07c-1.66,0-3-1.34-3-3V83.63C12.07,81.98,13.41,80.63,15.07,80.63z" />
                <path id="Rectangle_Copy_7" opacity="0.805" fill="#F9949A" enable-background="new    "
                  d="M54.37,53.95h20.14
             c1.66,0,3,1.34,3,3v54.21c0,1.66-1.34,3-3,3H54.37c-1.66,0-3-1.34-3-3V56.95C51.37,55.3,52.71,53.95,54.37,53.95z" />
                <path id="Rectangle_Copy_8" opacity="0.805" fill="#F9949A" enable-background="new    "
                  d="M96.73,31.42h20.14
             c1.66,0,3,1.34,3,3v76.75c0,1.66-1.34,3-3,3H96.73c-1.66,0-3-1.34-3-3V34.42C93.73,32.76,95.08,31.42,96.73,31.42z" />
              </g>
              <path id="pathMoveCrcl1" opacity="0.35" fill="none" enable-background="new    " d="M-0.3,77.51c0,0,3.68-18.25,21.1-14.9
           c17.42,3.35,17.41-31.19,43.64-31.19s17.57-25.26,45-25.26" />

              <path id="Path_42" opacity="0.35" fill="none" stroke="#FFFFFF" stroke-width="3" stroke-linecap="round"
                stroke-linejoin="round" stroke-miterlimit="10" enable-background="new    " d="
           M-0.3,77.51c0,0,3.68-18.25,21.1-14.9c17.42,3.35,17.41-31.19,43.64-31.19s17.57-25.26,45-25.26" />
              <circle id="crcl1" fill="#DCD7FA" cx="81.57" cy="24.3" r="7.12" />
            </g>
            <path id="Rectangle_Copy_6" opacity="0.083" fill="#FFFFFF" enable-background="new    " d="M505.59,304.04h134.1
         c6.63,0,12,5.37,12,12v115.89c0,6.63-5.37,12-12,12h-134.1c-6.63,0-12-5.37-12-12V316.04
         C493.59,309.41,498.96,304.04,505.59,304.04z" />

            <path id="statsCircle" opacity="0.54" fill="none" stroke="#707070" stroke-width="16" stroke-linecap="round"
              stroke-miterlimit="10" enable-background="new    "
              d="
         M572.64,344.94c-17.47,0-31.64,14.16-31.64,31.64c0,17.47,14.16,31.64,31.64,31.64c17.47,0,31.64-14.16,31.64-31.64" />
            <g id="Group_3-2" transform="translate(288.099 83.04) rotate(39)">
              <path id="Path_32-2" fill="none" stroke="#D8D8D8" stroke-width="2" stroke-miterlimit="10"
                d="M37.46,25.77l2.39,2.78" />
              <path id="Path_33-4" fill="none" stroke="#D8D8D8" stroke-width="2.0002" stroke-miterlimit="10.0012"
                d="M34.98,27.64l1.01,3.77" />
              <g id="crow">
                <g id="Path_34-4" transform="translate(0 0)">
                  <path id="Mask-38" fill="#26075B" d="M3.64,43.37v-0.11c0.02-2.53,0.91-4.98,2.54-6.93l6.97-8.23c-2.66-1.28-4.96-3.2-6.7-5.58
               c0,0,11.76-0.79,13.52-3.14c1.09-1.79,2.43-3.41,3.99-4.82c1.8-1.41,5.04-1.4,5.97-2.68c2.07-2.84,4.22-8.26,10.16-6.6
               c0.77,0.24,1.45,0.73,1.92,1.38l-0.13-0.24l4.14-0.3l-2.76,2.95l-0.03-0.07c2.22,6.48-0.34,19.11-13.3,21.21
               c-3.86,0.69-7.81,0.61-11.64-0.24L12.7,42.62c-1,2.13-2.98,3.64-5.3,4.04c-0.1,0.01-0.2,0.02-0.3,0.02
               C5.31,46.52,3.88,45.15,3.64,43.37z" />
                  <g>
                    <defs>
                      <path id="SVGID_1_" d="M3.64,43.37v-0.11c0.02-2.53,0.91-4.98,2.54-6.93l6.97-8.23c-2.66-1.28-4.96-3.2-6.7-5.58
                   c0,0,11.76-0.79,13.52-3.14c1.09-1.79,2.43-3.41,3.99-4.82c1.8-1.41,5.04-1.4,5.97-2.68c2.07-2.84,4.22-8.26,10.16-6.6
                   c0.77,0.24,1.45,0.73,1.92,1.38l-0.13-0.24l4.14-0.3l-2.76,2.95l-0.03-0.07c2.22,6.48-0.34,19.11-13.3,21.21
                   c-3.86,0.69-7.81,0.61-11.64-0.24L12.7,42.62c-1,2.13-2.98,3.64-5.3,4.04c-0.1,0.01-0.2,0.02-0.3,0.02
                   C5.31,46.52,3.88,45.15,3.64,43.37z" />
                    </defs>
                    <clipPath id="SVGID_2_">
                      <use xlink:href="#SVGID_1_" overflow="visible" />
                    </clipPath>
                    <g id="Path_34-5" transform="translate(0)" clip-path="url(#SVGID_2_)">
                      <path id="Path_34-6" fill="#D5CEFF" d="M41.34,5.93c0,0-6.96,2.52-4.56,10.48c2.39,7.96-11.22,17.25-30.33,6.11
                   C-12.98,11.21,35.09,2.34,35.09,2.34l7.39,1.97L41.34,5.93z" />
                    </g>
                  </g>
                </g>
                <circle id="Oval-7" fill="#FFFFFF" cx="40.15" cy="7.77" r="0.27" />
              </g>
            </g>
          </g>
          <circle id="ovel" opacity="0.177" fill="#E8D7FF" enable-background="new    " cx="732" cy="88.52" r="25.33" />
          <g id="peoples">
            <g id="Group_3" transform="translate(617.865 211.559)">
              <g id="man3">
                <path id="Path_31" fill="#FFA7A7" d="M44.31,126.68l-7.5-1.65l-9.66-2.62v2.62l1.62,1.65c0,0-11.73,4.54-13.04,7.31
             c-1.32,2.76,1.77,4.98,6.63,6.18s12.66-1.42,12.66-1.42l11.67-6.18L44.31,126.68z" />
                <path id="Path_26" fill="#EE7A7A" d="M98.9,54.51l-5.01,26.46l25.22-8.12l-7.44-24.26L98.9,54.51z" />
                <g id="Path_23" transform="translate(3.397 268.347)">

                  <linearGradient id="Mask-20_1_" gradientUnits="userSpaceOnUse" x1="-861.8261" y1="261.4076"
                    x2="-861.2361" y2="261.5636"
                    gradientTransform="matrix(43.5526 -10.8589 -9.6912 -38.8691 40084.5742 840.6276)">
                    <stop offset="0" style="stop-color:#E8D6FF" />
                    <stop offset="1" style="stop-color:#FFFFFF" />
                  </linearGradient>
                  <path id="Mask-20" fill="url(#Mask-20_1_)" d="M28.62,12.83c0,0,0,12.83-2.76,16.76S12.9,41.25,10.58,45.36
               s-3.29,9.84,0.79,9.84c4.07,0,32.78-10.25,34.28-12.99c1.5-2.74-2.39-36.05-2.39-36.05L28.62,12.83z" />
                  <g>
                    <defs>
                      <path id="SVGID_3_" d="M28.62,12.83c0,0,0,12.83-2.76,16.76S12.9,41.25,10.58,45.36s-3.29,9.84,0.79,9.84
                   c4.07,0,32.78-10.25,34.28-12.99c1.5-2.74-2.39-36.05-2.39-36.05L28.62,12.83z" />
                    </defs>
                    <clipPath id="SVGID_4_">
                      <use xlink:href="#SVGID_3_" overflow="visible" />
                    </clipPath>
                    <g id="Path_23-2" transform="translate(0 0)" clip-path="url(#SVGID_4_)">
                      <path id="Path_23-3" fill="#E8D7FF" d="M37.88,12.83c0,0-1.89,16.98-11.5,21.51S16.85,59.68,6.99,55.85s-7.3-12.66-7.3-12.66
                   l15.44-17.76l13.49-12.61H37.88z" />
                    </g>
                  </g>
                </g>
                <g id="Path_29" transform="translate(95.191 240.819)">

                  <linearGradient id="Mask-21_1_" gradientUnits="userSpaceOnUse" x1="-949.8078" y1="289.2938"
                    x2="-949.2178" y2="289.4498"
                    gradientTransform="matrix(25.0466 -6.2448 -11.1965 -44.9069 27039.9395 7095.1064)">
                    <stop offset="0" style="stop-color:#E8D6FF" />
                    <stop offset="1" style="stop-color:#FFFFFF" />
                  </linearGradient>
                  <path id="Mask-21" fill="url(#Mask-21_1_)" d="M5.75,15.35l5.84,10.59c0,0-2.02,26.03-1.29,28.96c0.73,2.94,5.59,1.12,5.59,1.12
               s15.2-21.23,15.2-24.27c0-3.05-16.69-23.01-16.69-23.01L5.75,15.35z" />
                  <g>
                    <defs>
                      <path id="SVGID_5_" d="M5.75,15.35l5.84,10.59c0,0-2.02,26.03-1.29,28.96c0.73,2.94,5.59,1.12,5.59,1.12
                   s15.2-21.23,15.2-24.27c0-3.05-16.69-23.01-16.69-23.01L5.75,15.35z" />
                    </defs>
                    <clipPath id="SVGID_6_">
                      <use xlink:href="#SVGID_5_" overflow="visible" />
                    </clipPath>
                    <g id="Path_29-2" transform="translate(0 0)" clip-path="url(#SVGID_6_)">
                      <path id="Path_29-3" fill="#E9D8FF" d="M14.57,19.55c0,0,6.47,11.26,3.24,16.04s-6.47,10.33-3.24,18.42S-5.08,67-5.08,67
                   V33.66l4.78-14.11H14.57z" />
                    </g>
                  </g>
                </g>
                <g id="rHandMan3">
                  <g>
                    <path id="Rectangle_Copy_10" opacity="0.153" fill="#093062" enable-background="new    " d="M-22.02-7.65L40.81-24.5
                 c6.94-1.86,14.07,2.26,15.93,9.19l11.15,41.58c1.86,6.94-2.26,14.07-9.19,15.92L-4.14,59.05c-6.94,1.86-14.07-2.26-15.93-9.19
                 L-31.21,8.27C-33.07,1.34-28.95-5.79-22.02-7.65z" />
                    <g id="Group_8" transform="translate(585.802 171.745)">
                      <path id="Rectangle-9" opacity="0.8" fill="#FFFFFF" enable-background="new    " d="M-606.58-185.92l61.1-16.38
                   c6.4-1.72,12.98,2.08,14.7,8.48l10.17,37.94c1.72,6.4-2.08,12.98-8.48,14.7l-61.1,16.38c-6.4,1.72-12.98-2.08-14.7-8.48
                   l-10.17-37.94C-616.78-177.63-612.98-184.21-606.58-185.92z" />
                      <g>
                        <path id="Rectangle-10" fill="#8A7CD9"
                          d="M-559.94-160.65l22.53-6.46c1.63-0.47,3.32,0.47,3.79,2.1l0,0
                     c0.47,1.63-0.47,3.32-2.1,3.79l-22.53,6.46c-1.63,0.47-3.32-0.47-3.79-2.1l0,0C-562.51-158.49-561.56-160.18-559.94-160.65z" />
                        <path id="Rectangle-11" fill="#8A7CD9"
                          d="M-559.94-177.23l22.53-6.46c1.63-0.47,3.32,0.47,3.79,2.1l0,0
                     c0.47,1.63-0.47,3.32-2.1,3.79l-22.53,6.46c-1.63,0.47-3.32-0.47-3.79-2.1l0,0C-562.51-175.07-561.56-176.77-559.94-177.23z" />
                      </g>
                      <g id="Group_7" transform="translate(21.622 32.028)">
                        <path id="Path-8" fill="#8A7CD9" d="M-614.53-187.34c0,0,0.77-1.32,3.9-2.16c3.13-0.84,5.78,0.69,8.92-0.15
                     s3.9-2.17,3.9-2.17l-3.36-12.54c0,0-0.77,1.32-3.9,2.16c-3.13,0.84-5.78-0.69-8.92,0.15c-3.13,0.84-3.9,2.17-3.9,2.17
                     L-614.53-187.34z" />

                        <path id="Path-9" fill="none" stroke="#8A7CD9" stroke-width="2.0002" stroke-linecap="round"
                          stroke-linejoin="round" stroke-miterlimit="10.0012" d="
                     M-611.49-180.74l-1.96-7.31" />
                      </g>
                    </g>
                  </g>
                  <g>
                    <path id="Path_32" fill="#FFA7A7"
                      d="M39.18,45.75l2.18-6.97l-0.89-7.99c-0.21-0.5-0.78-0.73-1.28-0.52
                 c-0.04,0.02-0.08,0.04-0.12,0.06l0,0c-0.77,0.84-1.2,1.94-1.21,3.07v2.57l-13.42,2.81l7.58,9.77L39.18,45.75z" />

                    <linearGradient id="Path_25_1_" gradientUnits="userSpaceOnUse" x1="-860.0913" y1="530.0249"
                      x2="-859.2953" y2="530.6759"
                      gradientTransform="matrix(59.3098 -17.0068 -15.7455 -54.9111 59402.3789 14569.8779)">
                      <stop offset="0" style="stop-color:#DBBEFF" />
                      <stop offset="1" style="stop-color:#FFFFFF" />
                    </linearGradient>
                    <path id="Path_25" fill="url(#Path_25_1_)" d="M96.73,67.75L67.92,67L39.19,45.66l-10.06,2.9c0,0,26.59,29.32,30.15,31.74
                 s38.48,7.99,38.48,7.99L100,71.87L96.73,67.75z" />
                  </g>
                </g>
                <g id="Path_37" transform="translate(41.675 49.115)">

                  <linearGradient id="Mask-22_1_" gradientUnits="userSpaceOnUse" x1="-902.6617" y1="481.4336"
                    x2="-901.8657" y2="482.0846"
                    gradientTransform="matrix(79.6829 -22.8487 -25.1163 -87.5908 84049.1172 21649.502)">
                    <stop offset="0" style="stop-color:#DBBEFF" />
                    <stop offset="1" style="stop-color:#FFFFFF" />
                  </linearGradient>
                  <path id="Mask-22" fill="url(#Mask-22_1_)"
                    d="M46.89,23.69c2.78-7.06,15.08-12.78,20.03-13.93s10.61,1.85,10.61,1.85
               s8.47,6.17,7.4,21.2s-19.55,72.17-19.55,72.17l-42.39,5.27l16.75-31.5C39.73,78.74,44.11,30.75,46.89,23.69z" />
                  <g>
                    <defs>
                      <path id="SVGID_7_" d="M46.89,23.69c2.78-7.06,15.08-12.78,20.03-13.93s10.61,1.85,10.61,1.85s8.47,6.17,7.4,21.2
                   s-19.55,72.17-19.55,72.17l-42.39,5.27l16.75-31.5C39.73,78.74,44.11,30.75,46.89,23.69z" />
                    </defs>
                    <clipPath id="SVGID_8_">
                      <use xlink:href="#SVGID_7_" overflow="visible" />
                    </clipPath>
                    <g id="Path_37-2" transform="translate(0 0)" clip-path="url(#SVGID_8_)">
                      <path id="Path_37-3" fill="#E1CAFF" d="M62.51,11.93c0,0,2.81,53.84-3.73,68.85s-33.8,42.97-33.8,42.97L5.01,109.89
                   l9.64-59.86L44.98,6.08L62.51,11.93z" />
                    </g>
                  </g>
                </g>
                <g id="Path_21" transform="translate(52.579 141.646)">

                  <linearGradient id="Mask-23_1_" gradientUnits="userSpaceOnUse" x1="-911.2456" y1="389.409"
                    x2="-912.1096" y2="389.477"
                    gradientTransform="matrix(52.6405 -15.0944 -30.1401 -105.1111 59774.4102 27240.9375)">
                    <stop offset="0" style="stop-color:#1C6EB0" />
                    <stop offset="1" style="stop-color:#0C3B78" />
                  </linearGradient>
                  <path id="Mask-23" fill="url(#Mask-23_1_)" d="M12.08,17.7c0,0,2.26,57.33,4.44,62.12c2.18,4.79,32.86,40.94,32.86,40.94
               l14.57-4.18L38.97,68.93l15.19-56.41L12.08,17.7z" />
                  <g>
                    <defs>
                      <path id="SVGID_9_" d="M12.08,17.7c0,0,2.26,57.33,4.44,62.12c2.18,4.79,32.86,40.94,32.86,40.94l14.57-4.18L38.97,68.93
                   l15.19-56.41L12.08,17.7z" />
                    </defs>
                    <clipPath id="SVGID_10_">
                      <use xlink:href="#SVGID_9_" overflow="visible" />
                    </clipPath>
                    <g id="Path_21-2" transform="translate(0 0)" clip-path="url(#SVGID_10_)">
                      <path id="Path_21-3" fill="#053466"
                        d="M34.08,47.8v33.41l23.11,63.77L3.75,126.37l5.17-56.18l6.36-38.98L34.08,47.8z" />
                    </g>
                  </g>
                </g>
                <g id="Path_22" transform="translate(0 141.646)">

                  <linearGradient id="Mask-24_1_" gradientUnits="userSpaceOnUse" x1="-861.0545" y1="389.5898"
                    x2="-861.6285" y2="389.4108"
                    gradientTransform="matrix(105.2197 -30.1713 -31.1117 -108.4995 102807.625 16349.5068)">
                    <stop offset="0" style="stop-color:#1860A1" />
                    <stop offset="1" style="stop-color:#13488D" />
                  </linearGradient>
                  <path id="Mask-24" fill="url(#Mask-24_1_)" d="M64.66,17.7c0,0-30.19,46.22-30.19,52.48c0,6.26-3.67,74.65-3.67,74.65
               l17.11-4.91l12.09-61.95l42.37-48.14l4.36-17.31L64.66,17.7z" />
                  <g>
                    <defs>
                      <path id="SVGID_11_" d="M64.66,17.7c0,0-30.19,46.22-30.19,52.48c0,6.26-3.67,74.65-3.67,74.65l17.11-4.91l12.09-61.95
                   l42.37-48.14l4.36-17.31L64.66,17.7z" />
                    </defs>
                    <clipPath id="SVGID_12_">
                      <use xlink:href="#SVGID_11_" overflow="visible" />
                    </clipPath>
                    <g id="Path_22-2" transform="translate(0 0)" clip-path="url(#SVGID_12_)">
                      <path id="Path_22-3" fill="#0D4780" d="M83.89,14.29c0,0-27.56,42.5-31.62,50.89s-10.9,38.4-10.9,55.72
                   s-4.01,37.07-22.84,31.24s5.91-95.42,5.91-95.42L45.5,23.16L72.82,9.24L83.89,14.29z" />
                    </g>
                  </g>
                </g>
                <g id="Path_30" transform="translate(84.186 16.541)">
                  <path id="Mask-25" fill="#FFA7A7" d="M5.8,7.43c-0.28,1.11-0.43,2.24-0.45,3.38c0.13,1.4,1.16,2.7,0.93,4.18
               c-0.63,3.97-3.25,10.07-3.25,10.07l3.25,0.69l5.48,12.93l15.67-1.45l2.08-24.76L11.46,7.31L5.8,7.43z" />
                  <g>
                    <defs>
                      <path id="SVGID_13_"
                        d="M5.8,7.43c-0.28,1.11-0.43,2.24-0.45,3.38c0.13,1.4,1.16,2.7,0.93,4.18
                   c-0.63,3.97-3.25,10.07-3.25,10.07l3.25,0.69l5.48,12.93l15.67-1.45l2.08-24.76L11.46,7.31L5.8,7.43z" />
                    </defs>
                    <clipPath id="SVGID_14_">
                      <use xlink:href="#SVGID_13_" overflow="visible" />
                    </clipPath>
                    <g id="Path_30-2" transform="translate(0 0)" clip-path="url(#SVGID_14_)">
                      <path id="Path_30-3" fill="#FD9595" d="M5.39,8.07c0,0,4,1.62,6.21,10.29S9.13,41.01,20,43.85s13.92-22.73,13.92-22.73
                   S26.79,6.16,25.96,6.16S9.73,3.01,9.73,3.01L5.39,8.07z" />
                    </g>
                  </g>
                </g>

                <linearGradient id="Path_24_1_" gradientUnits="userSpaceOnUse" x1="-861.2187" y1="529.7048"
                  x2="-860.4227" y2="530.3558"
                  gradientTransform="matrix(88.9479 -25.5054 -14.1395 -49.3103 84150.4844 4284.7822)">
                  <stop offset="0" style="stop-color:#DBBEFF" />
                  <stop offset="1" style="stop-color:#FFFFFF" />
                </linearGradient>
                <path id="Path_24" fill="url(#Path_24_1_)"
                  d="M104.72,67.4c-4.61,4.41-17.83,34.15-17.83,34.15l-43.9,24.16l0,10.1
             c0,0,49.61-12.51,52.51-15.06s20.16-25.48,24.16-36.63s-0.35-17.08-2.64-19.39C114.73,62.41,109.34,62.99,104.72,67.4z" />

                <linearGradient id="Path_27_1_" gradientUnits="userSpaceOnUse" x1="-857.4892" y1="533.1396"
                  x2="-857.8162" y2="532.4196"
                  gradientTransform="matrix(42.819 9.1067 9.7332 -45.7646 31646.748 32216.0977)">
                  <stop offset="0" style="stop-color:#593E83" />
                  <stop offset="1" style="stop-color:#2D1D4C" />
                </linearGradient>
                <path id="Path_27" fill="url(#Path_27_1_)" d="M104.19,33.82c0.18,1.02,0.17,4.62,0.78,4.95c0.6,0.33,1.31-3.23,2.19-3.23
             c0.88,0,3.11,2.24,2.72,4.46c-0.39,2.22-1.2,18.69,2.35,19.73c3.55,1.04,8.41-15.39,10.85-21.95s0.6-14.48,0.6-14.48
             s-1.6-6.24-6.56-9.42s-12.14-2.58-12.14-2.58S88.4,11.83,85.5,13.34c-2.9,1.51-3.55,5.14-0.9,8.56s7.85,2.5,10.15,3.37
             S104.01,32.8,104.19,33.82z" />
                <path id="Path_40" fill="none" stroke="#2D8BE0" stroke-linecap="round" stroke-linejoin="round"
                  stroke-miterlimit="10" d="
             M82.69,157.75c0,0-25.82,39.31-30.86,51.6c-4.94,12.04-14.16,34.91-11.11,73.73" />
              </g>
            </g>
            <g id="Group" transform="translate(0.303 201.169)">
              <g id="man1">
                <g id="Path_4" transform="translate(86.737 259.282)">

                  <linearGradient id="Mask-26_1_" gradientUnits="userSpaceOnUse" x1="-325.4342" y1="281.1601"
                    x2="-324.7412" y2="281.0621"
                    gradientTransform="matrix(26.7437 -10.8052 -21.8802 -54.1555 14864.5342 11750.1494)">
                    <stop offset="0" style="stop-color:#E8D6FF" />
                    <stop offset="1" style="stop-color:#FFFFFF" />
                  </linearGradient>
                  <path id="Mask-26" fill="url(#Mask-26_1_)" d="M4.52,28.91c0,0,17.92,40.24,21.52,40.23c3.6,0,4.67-8.36,5.1-14.65
               c0.44-6.28-1.05-8.82-2.97-15.93s4.3-17.48,4.3-17.48L21.65,8.1L4.52,28.91z" />
                  <g>
                    <defs>
                      <path id="SVGID_15_" d="M4.52,28.91c0,0,17.92,40.24,21.52,40.23c3.6,0,4.67-8.36,5.1-14.65c0.44-6.28-1.05-8.82-2.97-15.93
                   s4.3-17.48,4.3-17.48L21.65,8.1L4.52,28.91z" />
                    </defs>
                    <clipPath id="SVGID_16_">
                      <use xlink:href="#SVGID_15_" overflow="visible" />
                    </clipPath>
                    <g id="Path_4-2" transform="translate(0 0)" clip-path="url(#SVGID_16_)">
                      <path id="Path_4-3" fill="#D0B1F8"
                        d="M6.84,26.19L28,67.32v9.25h-7.36L5.51,46.81L-0.3,32.87l7.14-11.82V26.19z" />
                    </g>
                  </g>
                </g>
                <g id="Path-10" transform="translate(0 285.994)">

                  <linearGradient id="Mask-27_1_" gradientUnits="userSpaceOnUse" x1="-239.6841" y1="255.9563"
                    x2="-239.1071" y2="255.8373" gradientTransform="matrix(38.759 0 0 -55.9384 9300.1543 14349.2031)">
                    <stop offset="0" style="stop-color:#E8D6FF" />
                    <stop offset="1" style="stop-color:#FFFFFF" />
                  </linearGradient>
                  <path id="Mask-27" fill="url(#Mask-27_1_)" d="M35.27,6.16c0,0-11.66,3.15-17.36,12.1s-4.95,22.52-4.95,22.52L-0.3,60.59
               c0,0,5.25,4.35,14.55-1.73s24.21-29.8,24.21-29.8L35.27,6.16z" />
                  <g>
                    <defs>
                      <path id="SVGID_17_" d="M35.27,6.16c0,0-11.66,3.15-17.36,12.1s-4.95,22.52-4.95,22.52L-0.3,60.59c0,0,5.25,4.35,14.55-1.73
                   s24.21-29.8,24.21-29.8L35.27,6.16z" />
                    </defs>
                    <clipPath id="SVGID_18_">
                      <use xlink:href="#SVGID_17_" overflow="visible" />
                    </clipPath>
                    <g id="Path-11" transform="translate(0 0)" clip-path="url(#SVGID_18_)">
                      <path id="Path-12" fill="#D0B1F8" d="M19.08,16.48c2.26,4.59-2.96,14.99,0,19.24S8.78,68.6-0.3,66.96
                   c-9.09-1.64,9.9-50.49,9.9-50.49S16.81,11.88,19.08,16.48z" />
                    </g>
                  </g>
                </g>
                <g id="Path_33" transform="translate(93.592 145.812)">

                  <radialGradient id="Mask-28_1_" cx="-335.3936" cy="396.5402" r="0.877"
                    gradientTransform="matrix(68.8426 0 0 -133.5 23152.957 52984.2031)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#C27474" />
                    <stop offset="1" style="stop-color:#8E4040" />
                  </radialGradient>
                  <path id="Mask-28" fill="url(#Mask-28_1_)" d="M-0.3,15.08l40.21,67.7L8.23,127.29l14.42,12.37c0,0,41.71-42.04,45.45-56.88
               S50.71,6.16,50.71,6.16L-0.3,15.08z" />
                  <g>
                    <defs>
                      <path id="SVGID_19_" d="M-0.3,15.08l40.21,67.7L8.23,127.29l14.42,12.37c0,0,41.71-42.04,45.45-56.88S50.71,6.16,50.71,6.16
                   L-0.3,15.08z" />
                    </defs>
                    <clipPath id="SVGID_20_">
                      <use xlink:href="#SVGID_19_" overflow="visible" />
                    </clipPath>
                    <g id="Path_33-2" transform="translate(0 0)" clip-path="url(#SVGID_20_)">
                      <path id="Path_33-3" fill="#8E4040" d="M50.17,27.17c-6.19,19.16-12.92,30.8-2.84,49.67S16.9,134.52,3.94,134.52
                   S20.6,82.78,20.6,82.78l-2.73-41.24C17.87,41.54,56.35,8.01,50.17,27.17z" />
                    </g>
                  </g>
                </g>
                <g id="Path_3" transform="translate(34.163 135.444)">

                  <radialGradient id="Mask-29_1_" cx="-277.5287" cy="406.807" r="0.563"
                    gradientTransform="matrix(116.79 0 0 -173.979 32509.3203 70858.9453)"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#FFA9A9" />
                    <stop offset="1" style="stop-color:#FE7070" />
                  </radialGradient>
                  <path id="Mask-29" fill="url(#Mask-29_1_)" d="M69.18,25.45c0,0-13.49,41.28-31.11,73.39S-0.3,149.9-0.3,149.9l6.51,30.24
               c0,0,46.64-43.21,60.6-62.94s49.68-91.75,49.68-91.75L86.68,6.16L69.18,25.45z" />
                  <g>
                    <defs>
                      <path id="SVGID_21_" d="M69.18,25.45c0,0-13.49,41.28-31.11,73.39S-0.3,149.9-0.3,149.9l6.51,30.24
                   c0,0,46.64-43.21,60.6-62.94s49.68-91.75,49.68-91.75L86.68,6.16L69.18,25.45z" />
                    </defs>
                    <clipPath id="SVGID_22_">
                      <use xlink:href="#SVGID_21_" overflow="visible" />
                    </clipPath>
                    <g id="Path_3-2" transform="translate(0 0)" clip-path="url(#SVGID_22_)">
                      <path id="Path_3-3" fill="#F26868" d="M92.35,21.15c0,0-52.01,141.55-92.66,141.55S58.09,25.47,58.09,25.47L91.08-4.45
                   L92.35,21.15z" />
                    </g>
                  </g>
                </g>
                <path id="Path_6" fill="#FFA7A7" d="M153.77,20.89l1.19,14.91l-3.76,12.97l-9.73,7.18v16.42l-9.95,4.42l-6.22-12.44l4.41-28.56
             L153.77,20.89z" />
                <g id="Path_2" transform="translate(78.608 25.844)">
                  <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="-13.5504" y1="145.0593" x2="90.4635"
                    y2="55.4356">
                    <stop offset="0" style="stop-color:#DBBEFF" />
                    <stop offset="0.2208" style="stop-color:#DFC4FF" />
                    <stop offset="0.5291" style="stop-color:#E9D7FF" />
                    <stop offset="0.8872" style="stop-color:#F9F4FF" />
                    <stop offset="1" style="stop-color:#FFFFFF" />
                  </linearGradient>
                  <path fill="url(#SVGID_23_)"
                    d="M72.02,36.28c-13.81,1.61-37.41,7.54-47.12,12.38S-0.3,85.32-0.3,85.32l11.85,20.28
               c0,0,1.47,9.22,0,15.63c-1.46,6.41-7.91,19.01-7.91,19.01s13.13,4.79,17.74,3.6c6.77-1.75,13.39-10.47,20.14-11.06
               c10.22-0.89,20.52-0.13,30.5,2.27c0.47-11.07,1.8-22.09,3.97-32.96c3.39-15.76,1.81-22.52,9.38-29.13
               c6.08-5.32,15.25-3.61,21.97-7.57c-5.15-6.44-7.07-16.21-8.44-24.95C92.92,42.43,84.94,34.77,72.02,36.28z" />
                  <g id="rHandMan1">
                    <path id="Path_7" fill="#FFA7A7" d="M119.34,6.16l5.79-15.53l9.64-9.04l7.52-1.27v1.63l-6.37,3.69l1.31,8.25l-3.68,3.37
                 l-3.9,1.34l-2.97,13.38h-6.62L119.34,6.16z" />
                    <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="95.4133" y1="36.2761" x2="130.551"
                      y2="36.2761">
                      <stop offset="0" style="stop-color:#FFFFFF" />
                      <stop offset="1" style="stop-color:#FFFFFF" />
                    </linearGradient>
                    <path fill="url(#SVGID_24_)"
                      d="M111.74,61.3c6.46-9.31,18.81-55.14,18.81-55.14H119.3c0,0-13.12,30.11-19.22,33.74
                 c-0.39,0.23-4.18,0.83-4.59,0.96c-0.72,8.21,3.53,19.57,10.04,25.53C107.18,65.42,110.47,63.13,111.74,61.3z" />
                  </g>
                </g>

                <linearGradient id="Path_5_1_" gradientUnits="userSpaceOnUse" x1="-239.4762" y1="542.528" x2="-240.2302"
                  y2="541.528" gradientTransform="matrix(40.7388 0 0 -62.9734 9902.2832 34172.0859)">
                  <stop offset="0" style="stop-color:#5A3E84" />
                  <stop offset="1" style="stop-color:#2D1D4C" />
                </linearGradient>
                <path id="Path_5" fill="url(#Path_5_1_)" d="M115.8,55.46c2.7-10.92,1.3-13.97,2.7-21.74s4.37-11.77,10.57-16.51
             c6.19-4.74,17.07-4.87,20.14-5.05c3.07-0.18,4.82,3.15,5.58,5.53c0.76,2.39-1.79,6.46-1.79,6.46s-6.3,8.98-7.69,12.21
             c-1.39,3.23-0.75,5.97-1.78,7.38c-1.02,1.4-3.77-2.36-4.3-1.6c-0.53,0.76-0.98,9.25-0.98,9.25s0,15.04-12.95,21.91
             C112.36,80.15,113.11,66.38,115.8,55.46z" />
                <path id="Path_8" fill="none" stroke="#E04141" stroke-linecap="round" stroke-linejoin="round"
                  stroke-miterlimit="10" d="
             M125.24,160.91c0,0-32.73,96.45-87.51,141.55" />
              </g>
            </g>
            <g id="Group_2" transform="translate(323.162 377.988)">
              <g id="man2">
                <g id="Path_35" transform="translate(141.908 105.413)">

                  <linearGradient id="Mask-31_1_" gradientUnits="userSpaceOnUse" x1="-707.2992" y1="259.5869"
                    x2="-708.0742" y2="259.2669" gradientTransform="matrix(83.8103 0 0 -38.287 59348.6875 9959.7139)">
                    <stop offset="0" style="stop-color:#FFD3D3" />
                    <stop offset="1" style="stop-color:#FFA7A7" />
                  </linearGradient>
                  <path id="Mask-31" fill="url(#Mask-31_1_)" d="M74.15,44.45l-13.55-5.4L9.41,27.49L-0.3,16.97v-6.58l14.34-4.23L24.3,16.97
               l38.9,14.85l0.14-0.39l8.56-1.7h5.97v1.7l-4.05,1.7l4.05,1.23l3.04,1.27c1.88,0.97,2.9,3.06,2.51,5.14
               c-0.71,2.09-2.62,3.55-4.83,3.68H74.15z" />
                  <g>
                    <defs>
                      <path id="SVGID_25_"
                        d="M74.15,44.45l-13.55-5.4L9.41,27.49L-0.3,16.97v-6.58l14.34-4.23L24.3,16.97l38.9,14.85l0.14-0.39
                   l8.56-1.7h5.97v1.7l-4.05,1.7l4.05,1.23l3.04,1.27c1.88,0.97,2.9,3.06,2.51,5.14c-0.71,2.09-2.62,3.55-4.83,3.68H74.15z" />
                    </defs>
                    <clipPath id="SVGID_26_">
                      <use xlink:href="#SVGID_25_" overflow="visible" />
                    </clipPath>
                    <g id="Path_35-2" transform="translate(0)" clip-path="url(#SVGID_26_)">
                      <path id="Path_35-3" fill="#F5A3A3"
                        d="M5.23,12.24L17.99,22.6l66.45,25.11H29.69L5.23,39.05l-6.15-18.08v-8.73H5.23z" />
                    </g>
                  </g>
                </g>
                <g id="lHandMan2">
                  <g>
                    <path id="Rectangle-5" opacity="0.279" fill="#093062" enable-background="new    " d="M-64.49-3.45l77.19-54.1
                 c5.88-4.12,13.99-2.7,18.11,3.19l24.71,35.25c4.12,5.88,2.7,13.99-3.19,18.11l-77.19,54.1c-5.88,4.12-13.99,2.7-18.11-3.19
                 l-24.71-35.25C-71.8,8.78-70.37,0.67-64.49-3.45z" />
                    <g id="Group_5" transform="translate(246.017 363.602) rotate(-34)">
                      <path id="Rectangle-6" fill="#13488D" d="M-50.24-489.14l97.54,0c5.52,0,10,4.48,10,10l0,49.04c0,5.52-4.48,10-10,10h-97.54
                   c-5.52,0-10-4.48-10-10l0-49.04C-60.24-484.66-55.76-489.14-50.24-489.14z" />
                      <circle id="Oval-4" fill="#093062" cx="-6.43" cy="-436.77" r="4.42" />
                      <circle id="Oval_Copy_3" fill="#093062" cx="7.09" cy="-436.77" r="4.42" />
                      <g id="Group_4" transform="translate(15.323 16.946)" opacity="0.128">

                        <path id="Path-2" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" stroke-miterlimit="10" d="
                     M-46.24-483.01h0.87c1.93,0,3.5,1.57,3.5,3.5c0,1.93-1.57,3.5-3.5,3.5h-0.88" />

                        <path id="Path-3" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" stroke-miterlimit="10"
                          d="
                     M-60.24-483.01h14v7.87c0,1.93-1.57,3.5-3.5,3.5l0,0h-7c-1.93,0-3.5-1.57-3.5-3.5l0,0L-60.24-483.01z" />

                        <path id="Path-4" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" stroke-miterlimit="10" d="
                     M-56.74-489.14v2.63" />

                        <path id="Path-5" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" stroke-miterlimit="10" d="
                     M-53.24-489.14v2.63" />

                        <path id="Path-6" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" stroke-miterlimit="10" d="
                     M-49.74-489.14v2.63" />
                      </g>
                      <g id="linesCard1">
                        <path id="Rectangle-7" opacity="0.128" fill="#FFFFFF" enable-background="new    " d="M-7.33-471.29l31.55,0
                     c1.94,0,3.51,1.57,3.51,3.52v0c0,1.94-1.57,3.51-3.51,3.51H-7.33c-1.94,0-3.51-1.57-3.51-3.51v0
                     C-10.85-469.72-9.27-471.29-7.33-471.29z" />
                        <path id="Rectangle_Copy_9" opacity="0.128" fill="#FFFFFF" enable-background="new    " d="M-7.33-457.95l47.41,0
                     c1.94,0,3.51,1.57,3.51,3.52v0c0,1.94-1.57,3.51-3.51,3.51H-7.33c-1.94,0-3.51-1.57-3.51-3.51v0
                     C-10.85-456.38-9.27-457.95-7.33-457.95z" />
                      </g>
                    </g>
                  </g>
                  <g id="Path_15" transform="translate(0 0)">
                    <path id="Mask-32" fill="#FFA7A7"
                      d="M25.65,78.9c-2.7-4.24-17.13-44.04-17.13-44.04l-5.77-8.71c0,0-4.61-7.42-2.52-12.41
                 S4.9,6.65,7.68,6.18s7.77,8.51,7.77,8.51l0.93-2.38c0,0,3.5-3.19,4.85-1.66s0,2.8,0,6.18c0,1.61-2.65,5.52-3.68,10.97
                 c-0.14,1.9-0.01,3.82,0.38,5.68l24.92,35.66l12.45,5.22l5.59,6.73l-9.09,13.79C51.82,94.88,28.35,83.15,25.65,78.9z" />
                    <g>
                      <defs>
                        <path id="SVGID_27_"
                          d="M25.65,78.9c-2.7-4.24-17.13-44.04-17.13-44.04l-5.77-8.71c0,0-4.61-7.42-2.52-12.41
                     S4.9,6.65,7.68,6.18s7.77,8.51,7.77,8.51l0.93-2.38c0,0,3.5-3.19,4.85-1.66s0,2.8,0,6.18c0,1.61-2.65,5.52-3.68,10.97
                     c-0.14,1.9-0.01,3.82,0.38,5.68l24.92,35.66l12.45,5.22l5.59,6.73l-9.09,13.79C51.82,94.88,28.35,83.15,25.65,78.9z" />
                      </defs>
                      <clipPath id="SVGID_28_">
                        <use xlink:href="#SVGID_27_" overflow="visible" />
                      </clipPath>
                      <g id="Path_15-2" transform="translate(0)" clip-path="url(#SVGID_28_)">
                        <path id="Path_15-3" fill="#F89D9D" d="M10.41,6.73c0,0-6.6,13.14,1.06,19.86s23.25,48.75,23.25,48.75l32.13,19.54
                     l-21.46,8.85L1.71,85.94L-8.52,24.63l4.26-22.95L10.41,6.73z" />
                      </g>
                    </g>
                  </g>
                </g>
                <path id="Path_16" fill="#FFA7A7"
                  d="M83.56,32.56l2.38,23.51l5.25,6.66v18.36h15.91l2.22-20.52l-3.57-18.49l-11.3-9.53H83.56z" />
                <path id="Path_14" fill="#FFA7A7" d="M113.35,85.92l-4.8-16.78H93.23L90.3,88.67L113.35,85.92z" />
                <g id="Path_10" transform="translate(45.679 65.694)">

                  <radialGradient id="Mask-33_1_" cx="-611.9142" cy="300.0039" r="0.995"
                    gradientTransform="matrix(110.574 0 0 -109.051 67739.125 32732.1387)"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0" style="stop-color:#FFA9A9" />
                    <stop offset="1" style="stop-color:#FF5757" />
                  </radialGradient>
                  <path id="Mask-33" fill="url(#Mask-33_1_)" d="M39.88,13.07C28.91,11.88,18.09,9.56,7.6,6.16l-7.9,22.69
               c0,0,23.46,7.76,25.13,10.73s3.42,75.64,3.42,75.64l65.5-5.84L82.39,45.88l11.36,13.79l16.53-13.79c0,0-23.25-30.48-27.88-32.81
               S56.22,14.58,39.88,13.07z" />
                  <g>
                    <defs>
                      <path id="SVGID_29_" d="M39.88,13.07C28.91,11.88,18.09,9.56,7.6,6.16l-7.9,22.69c0,0,23.46,7.76,25.13,10.73
                   s3.42,75.64,3.42,75.64l65.5-5.84L82.39,45.88l11.36,13.79l16.53-13.79c0,0-23.25-30.48-27.88-32.81S56.22,14.58,39.88,13.07z
                   " />
                    </defs>
                    <clipPath id="SVGID_30_">
                      <use xlink:href="#SVGID_29_" overflow="visible" />
                    </clipPath>
                    <g id="Path_10-2" transform="translate(0 0)" clip-path="url(#SVGID_30_)">
                      <path id="Path_10-3" fill="#FF6767" d="M54.98,12.98c0,0-24.5,25.89,1.16,56.47s19.91,83.77-18.27,78.05
                   S-10.96,3.44-10.96,3.44l26.19-5.41h31.67L54.98,12.98z" />
                      <path id="Path_13" fill="#FF6767"
                        d="M82.39,45.88l25.85,7.59L95.93,66.82l-7.73-4.5L82.39,45.88z" />
                    </g>
                  </g>
                </g>
                <g id="Path_9" transform="translate(74.226 163.637)">

                  <linearGradient id="Mask-34_1_" gradientUnits="userSpaceOnUse" x1="-640.252" y1="202.101"
                    x2="-640.718" y2="201.472" gradientTransform="matrix(106.31 0 0 -128.126 68152.2734 25911.6621)">
                    <stop offset="0" style="stop-color:#1C6DB0" />
                    <stop offset="1" style="stop-color:#0C3B78" />
                  </linearGradient>
                  <path id="Mask-34" fill="url(#Mask-34_1_)" d="M-0.3,17.27l28.27,77.85l-6.89,33.54l16.5,5.62c0,0,19.88-31.72,19.88-39.17
               s-11.03-50-11.03-50l21.12,33.92l22.49,55.25l15.99-5.63L90.48,64.52L64.74,6.16L-0.3,17.27z" />
                  <g>
                    <defs>
                      <path id="SVGID_31_" d="M-0.3,17.27l28.27,77.85l-6.89,33.54l16.5,5.62c0,0,19.88-31.72,19.88-39.17s-11.03-50-11.03-50
                   l21.12,33.92l22.49,55.25l15.99-5.63L90.48,64.52L64.74,6.16L-0.3,17.27z" />
                    </defs>
                    <clipPath id="SVGID_32_">
                      <use xlink:href="#SVGID_31_" overflow="visible" />
                    </clipPath>
                    <g id="Path_9-2" transform="translate(0 0)" clip-path="url(#SVGID_32_)">
                      <path id="Path_11" fill="#0B3E7B" d="M32.06,10.29c0,0-11.23-4.72-13.71,33.63c-0.82,12.6,16.14,29.74,16.75,49.11
                   c0.63,19.92-12.88,69.32-12.88,69.32l-61.72-33.68V10.29l91.08-29.47L32.06,10.29z" />
                    </g>
                  </g>
                </g>
                <g id="Path_34" transform="translate(87.084 273.625)">

                  <linearGradient id="Mask-35_1_" gradientUnits="userSpaceOnUse" x1="-645.6754" y1="90.2585"
                    x2="-646.6714" y2="90.2585"
                    gradientTransform="matrix(23.6372 -6.7779 -15.3816 -53.642 16686.5781 496.8175)">
                    <stop offset="0" style="stop-color:#FFA9A9" />
                    <stop offset="1" style="stop-color:#FE7070" />
                  </linearGradient>
                  <path id="Mask-35" fill="url(#Mask-35_1_)" d="M6.95,20.27c0,0,2.39-10.78,6.94-11.37c4.55-0.59,6.52,0.04,9.24,4.85
               s1.34,10.7,2.79,16.44c1.44,5.74,9.4,11.18,9.56,20.27s-2.69,12.01-8.98,12.48c-6.29,0.47-12.61-3.02-15.46-10.65
               S6.95,20.27,6.95,20.27z" />
                  <g>
                    <defs>
                      <path id="SVGID_33_"
                        d="M6.95,20.27c0,0,2.39-10.78,6.94-11.37c4.55-0.59,6.52,0.04,9.24,4.85s1.34,10.7,2.79,16.44
                   c1.44,5.74,9.4,11.18,9.56,20.27s-2.69,12.01-8.98,12.48c-6.29,0.47-12.61-3.02-15.46-10.65S6.95,20.27,6.95,20.27z" />
                    </defs>
                    <clipPath id="SVGID_34_">
                      <use xlink:href="#SVGID_33_" overflow="visible" />
                    </clipPath>
                    <g id="Path_34-2" transform="translate(0 0)" clip-path="url(#SVGID_34_)">
                      <path id="Path_34-3" fill="#FF6767" d="M16.72,7.44c0,0-2.73,12.44,2.48,29.33c3.54,10.82,8.89,20.96,15.82,29.99H-9.07V10.47
                   L16.72,7.44z" />
                    </g>
                  </g>
                </g>
                <g id="Path_12" transform="translate(164.155 286.139)">

                  <linearGradient id="Mask-36_1_" gradientUnits="userSpaceOnUse" x1="-726.1943" y1="78.4939"
                    x2="-727.1902" y2="78.4939" gradientTransform="matrix(41.6968 0 0 -27.3013 30330.6543 2162.7974)">
                    <stop offset="0" style="stop-color:#FFA9A9" />
                    <stop offset="1" style="stop-color:#FE7070" />
                  </linearGradient>
                  <path id="Mask-36" fill="url(#Mask-36_1_)" d="M16.08,6.16c0,0,9.45,3.96,15.04,7.76s11.71,16.53,9.98,18.94
               S12.91,30,5.24,26.06S0.09,11.78,0.09,11.78L16.08,6.16z" />
                  <g>
                    <defs>
                      <path id="SVGID_35_" d="M16.08,6.16c0,0,9.45,3.96,15.04,7.76s11.71,16.53,9.98,18.94S12.91,30,5.24,26.06
                   S0.09,11.78,0.09,11.78L16.08,6.16z" />
                    </defs>
                    <clipPath id="SVGID_36_">
                      <use xlink:href="#SVGID_35_" overflow="visible" />
                    </clipPath>
                    <g id="Path_12-2" transform="translate(0 0)" clip-path="url(#SVGID_36_)">
                      <path id="Path_12-3" fill="#FF6767" d="M9.27,6.16c0,0,15.29,10.92,22.66,17.69s9.52,11.89,9.52,11.89H0.09l-8.2-23.96
                   L9.27,6.16z" />
                    </g>
                  </g>
                </g>
                <g id="Path_36" transform="translate(81.305 12.11)">

                  <linearGradient id="Mask-37_1_" gradientUnits="userSpaceOnUse" x1="-643.3733" y1="353.4803"
                    x2="-644.0423" y2="352.9803" gradientTransform="matrix(40.9811 0 0 -57.073 26410.543 20180.4258)">
                    <stop offset="0" style="stop-color:#593E83" />
                    <stop offset="1" style="stop-color:#2D1D4C" />
                  </linearGradient>
                  <path id="Mask-37" fill="url(#Mask-37_1_)" d="M6.22,7.38c-5.29,2.08-7.88,8.05-5.8,13.34c1.05,2.66,3.15,4.76,5.8,5.8
               c1.61,0.65,1.6,10.31,2.83,12.23c0.84,1.3,3.24-2.91,3.9-1.02c1.93,5.47,2.18,25.51,2.18,25.51h12.89l12.53-27.4
               c0,0,1.41-11.83-5.16-19.62S14.75,3.82,6.22,7.38z" />
                  <g>
                    <defs>
                      <path id="SVGID_37_" d="M6.22,7.38c-5.29,2.08-7.88,8.05-5.8,13.34c1.05,2.66,3.15,4.76,5.8,5.8
                   c1.61,0.65,1.6,10.31,2.83,12.23c0.84,1.3,3.24-2.91,3.9-1.02c1.93,5.47,2.18,25.51,2.18,25.51h12.89l12.53-27.4
                   c0,0,1.41-11.83-5.16-19.62S14.75,3.82,6.22,7.38z" />
                    </defs>
                    <clipPath id="SVGID_38_">
                      <use xlink:href="#SVGID_37_" overflow="visible" />
                    </clipPath>
                    <g id="Path_36-2" transform="translate(0 0)" clip-path="url(#SVGID_38_)">
                      <path id="Path_36-3" fill="#2D1D4C" d="M6.22,7.38c0,0,38.82,15.61,19.41,52.36S-3.66,37.38-3.66,37.38l-5.2-34.63L6.22,7.38z
                   " />
                    </g>
                  </g>
                </g>
                <path id="Path_41" fill="none" stroke="#2D8BE0" stroke-miterlimit="10" d="M135.72,169.8c0,0,14.57,32.82,23.29,55.91
             c8.72,23.1,17.47,68.08,17.47,68.08" />
              </g>
            </g>
          </g>
          <g id="birds">
            <g transform="translate(474.131 340.733) rotate(129)">
              <g id="bird1">
                <path id="Path_611" fill="#26075B"
                  d="M20.68,25.09l5.85,11.21l10.49,8.29L31.73,33.3l1.1-6.56l-7.97-4.6L20.68,25.09z" />
                <path id="Combined_Shape" fill="#EFECFF" d="M20.22,13.28l0.21,3.22l-6.57,3.42l-8.89,1.7l6,5.1l49.09,0l-24.98-8.84l-1.03-8.14
             L35.1-3.64L20.22,13.28z" />
                <circle id="Oval-8" cx="11.72" cy="24.37" r="0.53" />
              </g>
            </g>
            <g transform="matrix(0.588, 0.809, -0.809, 0.588, 781.107, 146.347)">
              <g id="bird2">
                <path id="Path_610" fill="#26075B" d="M19.3,34.26c-1.98-4.7-2.34-9.93-1.02-14.86c1.45-4.66,4.58-8.61,8.79-11.08l3.54,22.84
             l-0.26,3.9L19.3,34.26z" />

                <linearGradient id="Combined_Shape-2_1_" gradientUnits="userSpaceOnUse" x1="-115.3078" y1="1182.1655"
                  x2="-115.6018" y2="1181.1655"
                  gradientTransform="matrix(43.1898 -35.6963 -59.4227 -25.9449 75283.8359 26559.5703)">
                  <stop offset="0" style="stop-color:#ECE9FF" />
                  <stop offset="1" style="stop-color:#D5CEFF" />
                </linearGradient>
                <path id="Combined_Shape-2" fill="url(#Combined_Shape-2_1_)" d="M16.66,40.28L4.81,38.01l8-6.8h13.11
             c-0.43-3.66-0.66-12.25,5.31-18.48c7.77-8.11,22.84-8.86,22.84-8.86S43.78,18.23,42.79,23.49c-0.53,2.81,1.09,5.67,2.72,7.72
             h32.75c0,0-37.95,15.31-47.04,16.64c-0.61,0.09-1.23,0.14-1.85,0.14C21.4,47.99,16.66,40.28,16.66,40.28z" />
                <circle id="Oval-9" cx="13.8" cy="34.34" r="0.71" />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="col-md-6 py-md-5 pl-md-5">
        <h3 class="display-4 heading-main font-weight-bold text-white mt-3">We are the next global software development
          revolution</h3>
        <div class="theme-border-white-left my-5 px-3 pr-5 mr-5 ml-md-4 typing-wrap">
          <div>
            <p>Reimagine your business with the best software development solutions to help you design,
              define and deliver results in the modern era.
              Code Fusion serves you with new technologies to transform and drive your business at
              lightning speed for a digital, customer- first world.
              Our services comprise of Web Development, Android Development, IOS Development,
              SEO Services, JS Programming and much more to play with the code.
              We are a purpose-led organization building a meaningful future through innovation,
              technology and collective knowledge.</p>
          </div>
          <div class="readmore" [class.active]="readMore">
            <div>


              <p>a strong idea of innovation in technology services, we assist in helping
                companies and clients leverage the potential to get customized solutions to make
                your websites grow exceptionally well. We practice to produce the most reliable
                and scalable software development services for the Responsive Mobile-First world
                WeCode Fusion has successfully completed many Custom Software solutions from small,
                medium to large scale businesses Employing ideologies of web development and Android development.
                We go from idea to code ensuring the value, time and quality of software development. Our top software
                developers and extremely passionate team is forever available to assist you. So, if you are looking for
                an
                extensible, reliable and high performing mobile apps and web apps software, you are at the right place.
              </p>
            </div>
            <span class="btn-readmore" (click)="readMore = !readMore"></span>
          </div>
        </div>
        <a class="btn-group btn-split ml-md-5" role="group" href="home#contact-us" aria-label="Basic example">
          <button type="button" class="btn">Get Quote</button>
          <button type="button" class="btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" stroke="#6859bc" height="16.828" viewBox="0 0 18 16.828">
              <g transform="translate(-381 -650.586)">
                <path d="M-2,0H14" transform="translate(384 659)" fill="none" stroke-linecap="round" stroke-width="2" />
                <path d="M12,5l7,7-7,7" transform="translate(379 647)" fill="none" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="2" />
              </g>
            </svg>
          </button>
        </a>
        <a href="home#work" class="btn btn-outline-light rounded-0 btn-theme-lg ml-3" style="transition: 0.3s;">All
          Work</a>
      </div>
    </div>
  </div> -->
</section>