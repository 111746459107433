<section class="theme-bg-blue" style="line-height: 2;">
  <div class="container-fluid pt-5 pb-">
    <div class="row">
      <div class="col-md-3">
        <div class="container" style="font-size: 14px;">
          <img class="img-fluid mb-4" src="/assets/images/logo/code-fusion-light.png">
          <div >
            <div class="d-flex mb-md-2 mb-2">
              <div class="me-3">
                  <img src="../../../../assets/images/clients/location.svg" alt="location.svg">
              </div>
              <div class="text-white">
                  <p class="   mb-0">

                      41/75 Thaper Colony
                  </p>
                  <p class="mb-0">
                      Jagadhri, (135001)

                  </p>
              </div>
          </div>

          <div class="mb-md-2 mb-2 d-flex">
             
            <div class="me-3">
              <img src="../../../../assets/images/clients/mobile.svg" alt="mobile.svg">

          </div>
          <div class="text-white">
            <a class=" mb-0 text-white" href="tel:+918837624775" >
              +91 8837624775 
          </a>
          <a class="mb-0 text-white" href="tel:+918837624775" >
            +91 7709727784
        </a>

          </div>
          </div>

          <div class="d-flex ">
              <div class="me-3">
                  <img src="../../../../assets/images/clients/mail.svg" alt="mail">

              </div>
              <div>
                  <p class="mb-0 text-white">
                      <a href="mailto:hi@thecodefusion.com" class="text-white">hi@thecodefusion.com</a>
                  </p>
                  <p>
                      <a href="mailto:shivam@thecodefusion.com"
                          class="text-white">shivam@thecodefusion.com</a>
                  </p>

              </div>
          </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h5 class="text-white font-weight-semibold pt-4 ">Our Services</h5>
        <div class="border-bottom mb-4"></div>
        
        <ul class="footer-menu">
          <a href="">
            <li>
              &#x2022; &nbsp;&nbsp;Android Development
            </li>
          </a>
          <a href="">
            <li>
              &#x2022; &nbsp;&nbsp;IOS Development
            </li>
          </a>
          <a class="nav-item nav-link p-0" [routerLink]="'/web-development'"  routerLinkActive="active">
            
            <li>
              &#x2022; &nbsp;&nbsp;Web Development
            </li>
          </a>
        
          <a href="">
            <li>
              &#x2022; &nbsp;&nbsp;Web Design
            </li>
          </a>
          <a href="">
            <li>
              &#x2022; &nbsp;&nbsp;Seo Service
            </li>
          </a>
          <a href="">
            <li>
              &#x2022; &nbsp;&nbsp;Logo Design
            </li>
          </a>
        </ul>
      </div>
      <div class="col-md-3">
        <h5 class="text-white font-weight-semibold pt-4 ">Helpful Links</h5>
        <div class="border-bottom mb-4"></div>

        <ul class="footer-menu">
         
          <a class="nav-item nav-link p-0" [routerLink]="'/our-work'"  routerLinkActive="active">
            
            <li>
              &#x2022; &nbsp;&nbsp;Our Work
            </li>
          </a>
          <a class="nav-item nav-link p-0" [routerLink]="'/about-us'"  routerLinkActive="active">
            
            <li>
              &#x2022; &nbsp;&nbsp;About Us
            </li>
          </a>
          <!-- <a href="home#about-us">
            <li>
              &#x2022; &nbsp;&nbsp;About Us
            </li>
          </a> -->
          <a href="home#services">
            <li>
              &#x2022; &nbsp;&nbsp;Services
            </li>
          </a>
          <a href="home#contact-us">
            <li>
              &#x2022; &nbsp;&nbsp;Get Quote
            </li>
          </a>
          <a class="nav-item nav-link p-0" [routerLink]="'/our-teams'"  routerLinkActive="active">
            
            <li>
              &#x2022; &nbsp;&nbsp;Our team
            </li>
          </a>
          <a class="nav-item nav-link p-0" [routerLink]="'/case-study'"  routerLinkActive="active">
            
            <li>
              &#x2022; &nbsp;&nbsp;Case study
            </li>
          </a>
          <!-- <a class="nav-item nav-link p-0" [routerLink]="'/home-redesign'"  routerLinkActive="active">
            
            <li>
              &#x2022; &nbsp;&nbsp;Home redesign
            </li>
          </a> -->
      
          <!-- <a class="nav-item nav-link p-0" [routerLink]="'/careers'"  routerLinkActive="active">
            <li>
              &#x2022; &nbsp;&nbsp;Careers
            </li>
          </a> -->
        </ul>
      </div>
      <div class="col-md-3">
        <h5 class="text-white font-weight-semibold pt-4 ">Latest Projects</h5>
        <div class="border-bottom mb-4"></div>

        <ul class="footer-menu">
          <a href="">
            <li>
              &#x2022; &nbsp;&nbsp;Swasthya Shoppe
            </li>
          </a>
          <a href="">
            <li>
              &#x2022; &nbsp;&nbsp;Vitlekha
            </li>
          </a>
          <a href="">
            <li>
              &#x2022; &nbsp;&nbsp;Bloc Health
            </li>
          </a>
          <a href="">
            <li>
              &#x2022; &nbsp;&nbsp;Stupid ullu
            </li>
          </a>
          <a href="">
            <li>
              &#x2022; &nbsp;&nbsp;Fosso
            </li>
          </a>
          <a href="">
            <li>
              &#x2022; &nbsp;&nbsp;Seven Monies
            </li>
          </a>
        </ul>
      </div>
   
    </div>
  </div>
  <div class="container-fluid mt-2 py-2" style="border-top: 1px solid rgba(133, 118, 222, 0.6)">
    <div class="row text-center ">
      <div class="col-md-6 text-md-left">
        <div class="container">All Rights Reserved &copy; TheCodeFusion {{currentYear}}</div>
      </div>
      <div class="col-md-6 text-md-right">
        <div class="footer-social pr-md-5">
          <a href="https://www.facebook.com/thecodefusion">
            <img src="../../assets/images/icons/fb.svg">
          </a>
          <a href="https://twitter.com/The_CodeFusion">
            <img src="../../assets/images/icons/linkdin.svg">
          </a>
          <a href="https://www.instagram.com/thecodefusion/">
            <img src="../../assets/images/icons/twitterw.svg">
          </a>
          <a href="https://www.linkedin.com/company/code-fusion-private-limited/">
            <img src="../../assets/images/icons/insta.svg">
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
