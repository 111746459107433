import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-work',
  templateUrl: './our-work.component.html',
  styleUrls: ['./our-work.component.css']
})
export class OurWorkComponent implements OnInit {
  isHovered: boolean = false;
  isHoveredPc: boolean = false;
  isHoveredPhone: boolean = false;
  clients=[
    {
      image:"../../../../../../assets/images/clients/aftershock.svg",
      name:"Aftershock",
      category:"web development",
      site:"../../../../../../assets/images/clients/aftershock.jpg",
      content:"Most Amazon sellers hate combining 72 types of fees from Seller Central spreadsheets, to check if their products are profitable."
    },
    {
      image:"../../../../../../assets/images/clients/h-icon.png",
      name:"Hemavi",
      category:"web development",
      site:"../../../../../../assets/images/clients/hemavi.png",
      content:"Most Amazon sellers hate combining 72 types of fees from Seller Central spreadsheets, to check if their products are profitable."
    },
    {
      image:"../../../../../../assets/images/clients/play-range.jpg",
      name:"PlayeRange",
      category:"Apps development",
      site:"../../../../../../assets/images/clients/play-range-mobile.png",
      content:"Most Amazon sellers hate combining 72 types of fees from Seller Central spreadsheets, to check if their products are profitable."
    },
    {
      image:"../../../../../../assets/images/clients/james-stone.svg",
      name:"Jamestown Distributors",
      category:"web development",
      site:"../../../../../../assets/images/clients/jamestown.png",
      content:"Most Amazon sellers hate combining 72 types of fees from Seller Central spreadsheets, to check if their products are profitable."
    },
    {
      image:"../../../../../../assets/images/clients/model.svg",
      name:"Model",
      category:"Apps development",
      site:"../../../../../../assets/images/clients/modal-mobile.png",
      content:"Most Amazon sellers hate combining 72 types of fees from Seller Central spreadsheets, to check if their products are profitable."
    },
    {
      image:"../../../../../../assets/images/clients/ht-icon.jpg",
      name:"Housetable",
      category:"web development",
      site:"../../../../../../assets/images/clients/house-table.png",
      content:"Most Amazon sellers hate combining 72 types of fees from Seller Central spreadsheets, to check if their products are profitable."
    },
    {
      image:"../../../../../../assets/images/clients/mint.svg",
      name:"Mint",
      category:"web development",
      site:"../../../../../../assets/images/clients/mint.png",
      content:"Most Amazon sellers hate combining 72 types of fees from Seller Central spreadsheets, to check if their products are profitable."
    },
    {
      image:"../../../../../../assets/images/clients/inventory.svg",
      name:"Inventory",
      category:"web development",
      site:"../../../../../../assets/images/clients/inventory.png",
      content:"Most Amazon sellers hate combining 72 types of fees from Seller Central spreadsheets, to check if their products are profitable."
    },
    {
      image:"../../../../../../assets/images/clients/dainey.svg",
      name:"Dainemoney",
      category:"Apps development",
      site:"../../../../../../assets/images/clients/dianey-mobile.png",
      content:"Most Amazon sellers hate combining 72 types of fees from Seller Central spreadsheets, to check if their products are profitable."
    },
  ]



  

  constructor() { }

  ngOnInit(): void {
  }
  getIframeUrl(index:any): string {
    // Replace 'yourSiteArray' with your array containing the URLs for the iframes
    const siteUrls: string[] = [
        'https://www.aftershockpc.com/',
       
    ];

    // Return the URL based on the index
    if (index >= 0 && index < siteUrls.length) {
      return siteUrls[index];
  } else {
      return ''; // Return a default URL or handle out-of-bounds indices accordingly
  }}
  toggleHover(isHovering: boolean) {
    this.isHovered = isHovering;
  }
  toggleHoverPc(isHoveredPc: boolean) {
    this.isHoveredPc = isHoveredPc;
  }
  toggleHoverPhone(isHoveredPhone: boolean) {
    this.isHoveredPhone = isHoveredPhone;
  }
}
