import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base/base.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NgxTypedJsModule } from 'ngx-typed-js';

@NgModule({
  declarations: [BaseComponent, FooterComponent, HeaderComponent],
  imports: [CommonModule, RouterModule, NgxTypedJsModule],
})
export class ThemeModule {}
