<section data-aos="fade-up" class="section-contact py-5" style="overflow: visible;" id="contact-us">
  <div class="container-fluid">

    <div class="row" style="overflow: hidden;">
      <div class="col-12 text-center text-white">
        <h1 class="display-4 font-weight-bold">LET'S CONNECT</h1>
        <h6 class="">Fill the form below and we will be in touch with you shortly</h6>
      </div>
      <div class="col-12 my-4 mb-md-5 text-center text-white">
        <h5 class="pb-2">I'M REPRESENTATIVE OF A:</h5>
        <div class="row justify-content-center">
          <div class="col-auto btn-contact-wrap" [class.active]="!isLargeCompany" (click)="isLargeCompany= false">
            <button type="button" class="btn btn-circle btn-contact">
              <img src="assets/images/icons/wind.svg" alt="">
            </button>
            <h6 class="btn-contact-label">Small Business</h6>
          </div>
          <div class="col-auto btn-contact-wrap" [class.active]="isLargeCompany" (click)="isLargeCompany= true"><button
              type="button" class="btn btn-circle btn-contact">
              <img src="assets/images/icons/activity.svg" alt="">
            </button>
            <h6 class="btn-contact-label">Large Business</h6>
          </div>
        </div>
      </div>
      <div class="col-md-6 my-2 px-4 pr-5 order-md-last">
        <form class="form-theme" formGroup="contactForm">
          <div class="form-group mb-5">
            <label>Full Name</label>
            <input type="text" class="form-control" formControlName="name" placeholder="How should we call you?">
            <small class="form-text text-danger" *ngIf="isControlHasError('name','required')">
              <strong>This field is required</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('name','minlength')">
              <strong>Minimum length limit is 3</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('name','maxlength')">
              <strong>Maximum length limit is 100</strong>
            </small>
          </div>
          <div class="form-group my-5">
            <label>Phone</label>
            <input type="tel" class="form-control" formControlName="phone" placeholder="Enter valid phone number.">
            <small class="form-text text-danger" *ngIf="isControlHasError('phone','required')">
              <strong>This field is required</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('phone','pattern')">
              <strong>Invalid phone</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('phone','minlength')">
              <strong>Minimum length limit is 10</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('phone','maxlength')">
              <strong>Maximum length limit is 10</strong>
            </small>
          </div>
          <div class="form-group my-5">
            <label>Email Address</label>
            <input type="email" class="form-control" formControlName="email" placeholder="Enter a valid email address.">
            <small class="form-text text-danger" *ngIf="isControlHasError('email','required')">
              <strong>This field is required</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('email','email')">
              <strong>Invalid email address</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('email','minlength')">
              <strong>Minimum length limit is 3</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('email','maxlength')">
              <strong>Maximum length limit is 100</strong>
            </small>
          </div>
          <div class="form-group my-5">
            <label>Your Message</label>
            <textarea class="form-control" rows="3" formControlName="message"
              placeholder="Tell us more about your project."></textarea>
            <small class="form-text text-danger" *ngIf="isControlHasError('message','required')">
              <strong>This field is required</strong>
            </small>
            <small class="form-text text-danger" *ngIf="isControlHasError('message','minlength')">
              <strong>Minimum length limit is 20</strong>
            </small>
          </div>
          <a class="btn-group btn-split btn-split-secondary btn-hover-light btn-split-sm" role="group"
            aria-label="Basic example">
            <button type="button" class="btn" (click)="submitContactForm()"
              [disabled]="contactForm.invalid || formProgress">Send Message</button>
            <button type="button" class="btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" stroke="#6859bc" height="16.828"
                viewBox="0 0 18 16.828">
                <g transform="translate(-381 -650.586)">
                  <path d="M-2,0H14" transform="translate(384 659)" fill="none" stroke-linecap="round"
                    stroke-width="2" />
                  <path d="M12,5l7,7-7,7" transform="translate(379 647)" fill="none" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                </g>
              </svg>
            </button>
          </a>
        </form>
      </div>
      <div class="col-md-6 my-2 pr-md-4">
        <!-- <img class="img-fluid" src="assets/images/graphics/Map_view.png" alt=""> -->
        <div class="mapouter"><div class="gmap_canvas"><iframe width="770" height="510" id="gmap_canvas" src="https://maps.google.com/maps?q=41/75 Thaper Colony Jagadhri, 135001&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2yu.co">2yu</a><br><style>.mapouter{position:relative;text-align:right;height:510px;width:770px;}</style><a href="https://embedgooglemap.2yu.co">html embed google map</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:510px;width:770px;}</style></div></div>
      </div>
    </div>
  </div>
</section>