import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/theme/base/base.component';
const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      // {
      //   path: 'home',
      //   loadChildren: () =>
      //     import('./views/pages/home/home.module').then((m) => m.HomeModule),
      // },
      {
        path: 'expertise',
        loadChildren: () =>
          import('./views/pages/expertise/expertise.module').then(
            (m) => m.ExpertiseModule
          ),
      },
      {
        path: 'about-us',
        loadChildren: () =>
          import('./views/pages/about-us/about-us.module').then(
            (m) => m.AboutUsModule
          ),
      },
      {
        path: 'our-work',
        loadChildren: () =>
          import('./views/pages/our-work/our-work.module').then(
            (m) => m.OurWorkModule
          ),
      },
      {
        path: 'our-teams',
        loadChildren: () =>
          import('./views/pages/our-teams/our-teams.module').then(
            (m) => m.OurTeamsModule
          ),
      },
      {
        path: 'contact-us',
        loadChildren: () =>
          import('./views/pages/contact-us/contact-us.module').then(
            (m) => m.ContactUsModule
          ),
      },
      {
        path: 'case-study',
        loadChildren: () =>
          import('./views/pages/case-study/case-study.module').then(
            (m) => m.CaseStudyModule
          ),
      },
      {
        path: 'web-development',
        loadChildren: () =>
          import('./views/pages/web-development/web-development.module').then(
            (m) => m.WebDevelopmentModule
          ),
      },
      {
        path: 'app-development',
        loadChildren: () =>
          import('./views/pages/app-development/app-development.module').then(
            (m) => m.AppDevelopmentModule
          ),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./views/pages/home-redesign/home-redesign.module').then(
            (m) => m.HomeRedesignModule
          ),
      },
      // {
      //   path: 'hiring',
      //   loadChildren: () =>
      //     import('./views/pages/hiring/hiring.module').then(
      //       (m) => m.HiringModule
      //     ),
      // },
      {
        path: 'careers',
        loadChildren: () =>
          import('./views/pages/careers/careers.module').then(
            (m) => m.CareersModule
          ),
      },

      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'}
  ),],
  exports: [RouterModule],
})
export class AppRoutingModule {}
