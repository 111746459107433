<div class="container-fluid" data-aos="fade-up">
    <div class="row gray-star w-75 m-auto">
        <div class="col text-center py-md-5 py-3 " data-aos="fade-up">
            <p class="font-14 color-gray font-weight-600 ">CODEFUSION PLATEFORM</p>
            <p class="font-48 font-weight-600 gradient-font margin-bottom-10 ">Strong Culture
            </p>
            <p class="font-48 font-weight-600 gradient-font">
                Stronger Companies
            </p>
            <p class="font-18 w-50 m-auto color-black">In publishing and graphic design, Lorem ipsum is a placeholder text commonly
                used to demonstrate the
                visual form of a document </p>
        </div>
    </div>
</div>


<!-- trophy -->
<div class="container-fluid mt-5 gradient-gray mb-0" data-aos="fade-up">
    <div class="row gradient-gray w-75 m-auto justify-content-center py-md-3 py-5">
        <div class="col d-sm-flex d-none justify-content-center" data-aos="fade-up">
            <div class="col d-sm-flex d-none justify-content-center">
                <img src="../../../../assets/images/clients/trofi.svg" alt="trophy" class="trophy-img">
            </div>
        </div>
        <div class="col " data-aos="fade-up">
            <div class="review-box text-center">
                <div><img src="./../../../../assets/images/clients/five-star.svg" alt="star"></div>
                <div class="font-12 underline font-weight-500">(4.00 ratings out of 200 reviews )</div>
                <div> <img src="../../../../assets/images/clients/google.svg" alt="google"></div>
            </div>
        </div>
        <div class="col d-sm-flex d-none justify-content-center">
            <img src="../../../../assets/images/clients/trofi-r.svg" alt="trophy" class="trophy-img">
        </div>
    </div>
</div>


<div class="container-fluid  " data-aos="fade-up" >
    <div class="row  w-50 m-auto justify-content-start py-md-3 pb-5 text-center">
        <div class="col border-right">
          <p class="number mb-0">200+</p>
          <p class="font-14 gray-2">STARTUP PROJECT</p>
        </div>
        <div class="col ">
            <p class="number mb-0">30+</p>
            <p class="font-14 gray-2">INDUSTRIES SERVED</p>
        </div>
        <div class="col border-left">
            <p class="number mb-0">120+</p>
            <p class="font-14 gray-2">CLIENTS</p>
        </div>
    </div>
</div>

<div class="container-fluid  p-md-5 " data-aos="fade-up">
    <div class="row px-md-5 gray-3 rounded  mx-md-5 p-3">

        <div class="col-md-6 col-12 d-flex flex-column justify-content-center" data-aos="fade-up">

            <p class="color-purple underline font-28">About Codefusion</p>
            <p class="line-height">At Code Fusion Private Limited, we transcend the ordinary to redefine digital landscapes. Established with a vision to revolutionize web development, we are a dynamic team of passionate professionals committed to delivering unparalleled solutions.</p>
            <p class="line-height">At Code Fusion Private Limited, we transcend the ordinary to redefine digital landscapes. Established with a vision to revolutionize web development, we are a dynamic team of passionate professionals committed to delivering unparalleled solutions.</p>
        </div>
        <div class="col-md-6 col-12" data-aos="fade-up">
            <img src="
            ../../../../../../assets/images/clients/mobile-pc.png" alt="home" class="home-screen">
        </div>
    </div>
</div>


<div class="container-fluid gradient-2 p-md-5 " data-aos="fade-up">
    <div class="row px-md-5  mx-0 p-3">
        <div class="col-md-6 col-12" data-aos="fade-up">
            <img src="
            ../../../../../../assets/images/clients/mix.svg" alt="grid" class="home-screen">
        </div>
        <div class="col-md-6 col-12 d-flex flex-column justify-content-center" data-aos="fade-up">
            <p class="font-14 color-gray font-weight-600">WHY CODEFUSION?</p>
            <p class="font-36  gradient-font">Our Experiences...

            </p>
            <p class="line-height pe-md-5">At Code Fusion, we don't just develop websites; we sculpt digital experiences that leave a lasting impression. Join us in the pursuit of digital excellence, where innovation meets expertise, and every line of code tells a story of ambition and achievement. Your vision, our expertise – together, we shape the future of the web.

            </p>
           
        </div>
       
    </div>
</div>

<div class="container-fluid p-md-5 py-4 px-2 " data-aos="fade-up" >
    <p class="font-14 color-gray font-weight-600 text-center">CODEFUSION PLATEFORM</p>
    <p class="font-36  gradient-font text-center">What Codefusion stands for</p>
    <div class="row px-md-5 px-3 pt-md-5 pt-3">
        <div class="col-md-6 custom-border py-md-5 py-3" *ngFor="let item of stands" data-aos="fade-up">
            <div class="d-md-flex ">
                <div ><img [src]="item.image" alt="" class="icons p-md-2 p-2"></div>
                <div class="px-4">
                    <p class="color-blue font-18">{{item.heading}}</p>
                    <p class="color-black font-16 line-height">{{item.content}}
                    </p>
                </div>
            </div>
           
        </div>
    </div>
</div>