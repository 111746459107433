<section class=" position-relative">
    <img src="../../../../assets/images/clients/white-circle.svg" alt="" class="white-circle">

    <img src="../../../../assets/images/clients/pink-circle.svg" alt="" class="pink-circle">
    <div class="container-fluid bg-purple    ">
        <div class="row  h-100 bg-home mx-auto" style="max-width: 1440px;">
            <div class="col-lg-10 col-12 m-auto d-flex align-items-center  justify-content-center">

                <div class="row">
                    <div class="col-xl-6 col-12 m-auto scroll-container-for-header   p-0">
                        <ngx-slick-carousel class="carousel-2" #slickModal="slick-carousel"
                            [config]="slideConfigConstant">


                            <div class="last-child-div d-flex  pt-3 " ngxSlickItem *ngFor="let item of mainData">
                                <div>


                                    <div class="font-44 text-white  ">
                                        <p class="font-14 font-weight-500 mb-3">INTRODUCTION</p>
                                        <p class="mb-0 font-weight-300">Empowering Businesses </p>
                                        <p class=" mb-0 font-weight-300">Through
                                            <span class="font-weight-600 mb-0">
                                                Digital Excellence </span>
                                        </p>
                                    </div>
                                    <div class="col-12 ">
                                        <p class="text-white line-height mt-3 wrap">Reimagine your business with the
                                            best software development solutions to help you design, define and deliver
                                            results in the modern era. Code Fusion serves you with new technologies to
                                            ... </p>
                                        <a href="#" class="underline cursor-pointer text-white mb-4">Read More</a>
                                    </div>


                                </div>
                            </div>
                        </ngx-slick-carousel>
                        <div class="mt-4 d-md-flex ">
                            <button
                                class="gradient-btn d-flex align-items-center  low-opacity-white-border text-white me-3 "
                                [routerLink]="['/our-work']" routerLinkActive="router-link-active">View All
                                Work
                                <img src="../../../../assets/images/clients/white-right-arrow.svg" alt="arrow"
                                    class="ms-2"></button>
                            <button class=" bg-transparent text-white  gradient-border-button border-gradient ">Get
                                Quote</button>
                        </div>
                    </div>



                    <div class="col-xl-6 d-xl-flex d-none">
                        <img src="../../../../assets/images/clients/circle.svg" alt="" class="align-self-end  left">
                        <img src="../../../../assets/images/clients/earth.svg" alt="earth"
                            class="w-100 img-responsive z-index-99">
                        <img src="../../../../assets/images/clients/circle.svg" alt="" class="align-self-start  right">

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<div class="container-fluid margin-negative" data-aos="fade-up">
    <div class="row   mx-auto" style="max-width: 1440px;">
        <div class="col-md-9 m-auto d-flex bg-white p-md-4 p-3 shadow rounded-10 gradient-row justify-content-center">
            <div class="row">
                <marquee>
                <div class=" d-flex">
                    <div class="p-3">


                        <img src="../../../../assets/images/clients/client-3.svg" alt="companies" class="clients">
                    </div>
                    <div class="p-3">

                        <img src="../../../../assets/images/clients/client-2.svg" alt="companies" class="clients">
                    </div>
                    <div class="p-3">

                        <img src="../../../../assets/images/clients/client-1.svg" alt="companies" class="clients">
                    </div>

                    <div class="p-3">

                        <img src="../../../../assets/images/clients/client-4.svg" alt="companies" class="clients">
                    </div>



                </div>
            </marquee>
            </div>
        </div>

    </div>
</div>




<div class="container-fluid  mt-md-5 mt-3" data-aos="fade-up">
    <div class="row mx-auto" style="max-width: 1440px;">
        <div class="col-md-7  m-auto bg-white  p-md-4 p-3  text-center">
            <p class="color-gray font-weight-600 font-28">WHAT WE THINK</p>
            <p class="color-gray">We believe in empowering your business at the intersection of strategy, design and
                technology and commit
                to create a vibrant and relevant experience for your website.Code Fusion is an innovation-led web and
                mobile development source for your entire IT outsourcing services. Together with </p>
            <p>
                <button class="get-quote-btn bg-transparent color-purple border-white font-weight-500">Learn More <img
                        src="../../../../assets/images/clients/purple-arrow-right.svg" alt="">
                </button>
            </p>
        </div>
    </div>
</div>









<!-- <div ngxSlickItem *ngFor="let slide of ['', '']" class="slide">
    dfgdfg
</div> -->

<div class="container-fluid  mb-md-5 mb-3 " data-aos="fade-up">
    <div class="row gradient-row-purple ">
        <div class="col-md-12 m-auto scroll-container   p-0">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">



                <div class="custom-purple-box-grand-parent me-3  d-flex slide" ngxSlickItem
                    *ngFor="let item of categoryNew">
                    <div class="custom-purple-box-parent   ">
                        <div [ngClass]="item.image + '  rounded-16 cursor-pointer  '" routerLink="/web-development">
                            <div class="gradient text-white d-flex flex-column justify-content-end p-md-4 p-3">

                                <div style="min-height:140px;">

                                    <p class="font-21 font-weight-600 ">{{item.title}}</p>
                                    <p class="pe-3">{{item.content}}
                                    </p>
                                </div>

                            </div>



                        </div>

                    </div>
                </div>
            </ngx-slick-carousel>
        </div>

    </div>


</div>


























<!-- choose section -->





<div class="container-fluid background-light-purple py-5">
    <div class="row mx-auto" style="max-width: 1440px;">
        <div class="col-md-7  m-auto   p-md-4 p-3  text-center">
            <p class="color-purple font-14 font-weight-600 "><span class="styled-14 px-2">Just take a look</span></p>
            <p class="font-28 color-gray font-weight-600">Why <span class="color-purple"> choose </span> us?</p>
            <p class="color-gray-2">At Code Fusion, our vision is to see grow profoundly as your competitor through our
                excellent range or.
                Web design and development Solution.</p>
        </div>
    </div>
    <div class="row mx-auto" style="max-width: 1440px;">
        <div class="col-md-10 m-auto">
            <div class="row">
                <div class="col-md-6 " data-aos="fade-up">
                    <div class="  d-flex mb-md-2 mb-1 " *ngFor="let item of choose">
                        <div class="me-3">
                            <img [src]="item.image" alt="computer" class="custom-img-for-choose">
                        </div>
                        <div>

                            <p class="font-20 font-weight-500  color-gray mb-0">{{item.title}}</p>
                            <p class="pe-3 font-14 color-gray-2">{{item.content}} </p>
                        </div>

                    </div>

                </div>
                <div class="col-md-6 mb-md-4 mb-3 d-flex align-items-center justify-content-center" data-aos="fade-up">

                    <img src="../../../../assets/images/clients/home-choose-image.svg" alt="image" class="w-100">
                </div>
            </div>

        </div>

    </div>
</div>






<div class="container-fluid">
    <!-- <div class="row mx-auto" style="max-width: 1440px;">
        <div class="col  custom-white-box background-light-purple">

        </div>
    </div> -->
</div>
<!-- <div class="container-fluid  pb-md-5 px-0">

    <img src="../../assets/images/clients/wave-arrow-with-earth.png" alt="" class="w-100">


    <div class="row pb-md-5 pb-3 custom-relative mx-auto" style="max-width: 1440px;">
        <div class="col-md-10 m-auto  shadow bg-white rounded-20">
            <div class="row pt-md-5 pt-3">
                <div class="col-md-7  m-auto   p-md-4 p-3  text-center">
                    <p class="font-28 color-gray font-weight-600">A Place to <span class="color-purple"> Grow </span>
                        your Start up</p>
                    <p class="color-gray-2">Whether you're seeking to establish a compelling online presence, enhance
                        user engagement, or launch a feature-rich Android application, Code Fusion is here to turn your
                        vision into a digital reality.</p>
                </div>
            </div>
            <div class="row p-md-4 p-3">
                <div class="col-md-12 col-12 m-auto">
                    <div class="row">
                        <div class="col-md-4 col-12 mb-md-4 mb-4 " *ngFor="let item of grow" data-aos="fade-up">
                            <div class=" hover-purple cursor-pointer  text-center rounded-33  p-md-4 p-3 mb-md-0 mb-5">
                                <img [src]="item.image" alt="user" class="icon-img default-image">
                                <img [src]="item.image2" alt="user" class="icon-img hover-image">

                                <p class="font-weight-600 font-18 my-md-3 my-2  color-purple">{{item.title}}</p>

                                <p class="font-16 color-gray-2 line-height"> {{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div> -->


























<div class="container-fluid  mt-md-5 mt-3" data-aos="fade-up">
    <div class="row mx-auto" style="max-width: 1440px;">
        <div class="col-md-7  m-auto bg-white  p-md-4 p-3  text-center">
            <p class="color-gray font-weight-600 font-28">At our excellence</p>
            <p class="color-gray">At Code Fusion, we are a professional team that knows creativity simply does not turn
                up; it takes a lot of diligence, efforts and dedication. That’s why we leave no stone unturned to get
                the projects of our dear client carried out systematically and within a time span stipulated</p>
            <p>
                <button class="get-quote-btn bg-transparent color-purple border-white font-weight-500">Learn More <img
                        src="../../../../assets/images/clients/purple-arrow-right.svg" alt="">
                </button>
            </p>
        </div>
    </div>



    <div class="row my-md-4 my-3 mx-auto" style="max-width: 1440px;">
        <div class="col-md-10 col-12 m-auto">
            <div class="row">
                <div class="col-md-3 col-12 mb-md-4 mb-4" *ngFor="let item of excellence" data-aos="fade-up">
                    <div class="rounded-16 rounded-20 p-md-3 p-3" [ngClass]="item.backgroundColor">

                        <p class="font-weight-600 font-42" [ngClass]="item.fontColor">{{ item.number }}</p>
                        <p class="pe-3 color-gray-2">{{ item.reaction }}</p>
                        <p class="font-weight-600 color-purple">{{ item.words }}</p>
                    </div>
                </div>
            </div>



        </div>

    </div>


    <div class="row pb-md-5 pb-3  mx-auto" style="max-width: 1440px;">
        <div class="col-md-7  m-auto   p-md-4 p-3  text-center">
            <p class="color-purple font-14 font-weight-600 "> <span class="styled-14 px-2">Recents Works</span></p>
            <p class="font-28 color-gray font-weight-600">And <span class="color-purple"> case studies </span> </p>
            <p class="color-gray-2">Checkout some of the featured projects we have been working on. Want to see more?
                Contact us for more information.</p>
        </div>
    </div>
</div>









<div class="container-fluid  ">

    <div class="row mx-auto" style="max-width: 1440px;">
        <div class="col m-auto ">
            <div class="row">
                <div class="col-md-6 py-md-5 py-3 background-gray d-flex align-items-center justify-content-center"
                    data-aos="fade-up">
                    <img src="../../../../assets/images/clients/inventry.svg" alt="image" class="inventry-img">


                </div>
                <div class="col-md-6 py-md-5 py-3  background-purple height-500" data-aos="fade-up">
                    <div class="m-auto scroll-container-for-header-2   p-0">
                        <ngx-slick-carousel class="carousel-3" #slickModal="slick-carousel"
                            [config]="slideConfigConstant">


                            <div class="last-child-div d-flex  pt-3 " ngxSlickItem *ngFor="let item of mainData">
                                <div>

                                    <div class="font-54 text-white ">
                                        <div>
                                            <img src="../../../../assets/images/clients/inventry-logo.svg"
                                                alt="inventory-logo">
                                        </div>
                                        <p class="font-weight-600 mb-0 font-28">Systemize your
                                        </p>
                                        <p class="font-weight-600 font-28"> e-commerce company</p>
                                    </div>
                                    <div class=" ">
                                        <p class="text-white"> I've already saved thousands by preventing profit loss
                                            with Inventory
                                            Ahead. I can cancel my subscriptions to all the other services I was using
                                            and just take
                                            care of everything in one place, which saves me hundreds more dollars every
                                            month. </p>

                                    </div>

                                </div>
                            </div>
                        </ngx-slick-carousel>
                        <div class=" d-md-flex">
                            <div class=" mt-md-5 mt-5 mb-md-0 d-md-flex">
                                <button class="get-quote-btn me-md-3 bg-transparent text-white border-white">View on
                                    Play
                                    Store</button>

                                <button class="get-quote-btn bg-transparent text-white " 
                                [routerLink]="['/case-study']" routerLinkActive="router-link-active"
                                >Case Study <img
                                        src="../../../../assets/images/clients/white-right-arrow.svg"
                                        alt="arrow"></button>
                            </div>

                        </div>
                    </div>


                    <!-- <div class="d-flex" >

                        <div class="last-child-div mx-md-5 mx-2 my-md-0 my-4"  >
                            <div class="font-54 text-white ">
                                <div>
                                    <img src="../../../../assets/images/clients/inventry-logo.svg" alt="inventory-logo">
                                </div>
                                <p class="font-weight-600 mb-0 font-28">Systemize your
                                </p>
                                <p class="font-weight-600 font-28"> e-commerce company</p>
                            </div>
                            <div class=" ">
                                <p class="text-white"> I've already saved thousands by preventing profit loss with Inventory
                                    Ahead. I can cancel my subscriptions to all the other services I was using and just take
                                    care of everything in one place, which saves me hundreds more dollars every month. </p>

                            </div>

                            <div class=" mt-md-5 mt-5 mb-md-0 ">
                                <button class="get-quote-btn me-md-3 bg-transparent text-white border-white">View on Play
                                    Store</button>

                                <button class="get-quote-btn bg-transparent text-white ">Case Study <img
                                        src="../../../../assets/images/clients/white-right-arrow.svg" alt="arrow"></button>
                            </div>

                            <div class=" mt-md-5  pt-md-5 pt-3 mt-3 d-flex justify-content-between align-items-center">
                                <div>
                                    <button
                                        class="get-quote-btn  rounded-50 me-md-3 bg-transparent text-white border-white">
                                        <img src="../../../../assets/images/clients/left.svg" alt="arrow">


                                    </button>
                                </div>


                                <div>
                                    <img src="../../../../assets/images/clients/dots.svg" alt="dots">
                                </div>
                                <div>
                                    <button class="get-quote-btn bg-transparent rounded-50 text-white ">
                                        <img src="../../../../assets/images/clients/right.svg" alt="arrow">
                                    </button>
                                </div>

                            </div>



                        </div>
                    </div> -->

                </div>
            </div>

        </div>

    </div>
</div>






<div class="container-fluid  ">

    <div class="row mx-auto" style="max-width: 1440px;">
        <div class="col-10 m-auto ">
            <div class="row">

                <div class="col-md-6 py-md-5 py-3   d-flex align-items-center" data-aos="fade-up">
                    <div class="last-child-div mx-md-5 mx-2 my-md-0 my-4">
                        <div class="  ">


                            <p class="font-weight-500 color-purple font-28">The Key to designing the most exclusive
                                websites</p>
                            <p class="font-16 color-gray-2 line-height ">Software development can be a template for the
                                new age
                                technologies, hence we firmly believe in your growth as its own and establishing common
                                standards of coding and building your trust in the code. Code fusion, is committed in
                                establishing agile methodologies to scale your business and establishing an independent
                                quality assurance team for testing standards. Through this unprecedented technology
                                transformation, it took us years to be among the leading software development companies
                                combining development and IT ops for operationalization and maintenance in collaboration
                                with our best team.</p>
                        </div>


                        <div class=" mt-md-5 mt-5 mb-md-0 ">


                            <button class="get-quote-btn bg-transparent color-purple border-purple ">Our Services <img
                                    src="../../../../assets/images/clients/purple-arrow-right.svg" alt="arrow"></button>
                        </div>
                    </div>
                </div>



                <div class="col-md-6 py-md-5 py-3 d-flex align-items-center justify-content-center" data-aos="fade-up">
                    <img src="../../../../assets/images/clients/human.svg" alt="image" class="inventry-img">


                </div>
            </div>

        </div>

    </div>
</div>

<div class="container-fluid background-light-purple  mt-md-5 mt-3" data-aos="fade-up">






    <div class="row pb-md-4 pb-3 pt-md-5 pt-3">
        <div class="col-md-7  m-auto   p-md-4 p-3  text-center">
            <p class="color-purple font-14 font-weight-600 mb-0 "><span class="styled-14 px-2">Our Client's</span></p>
            <p class="font-28 color-gray font-weight-600">Testimonials </p>
        </div>
    </div>
    <div class="row mt-md-4 mt-3 scroll-container-1">
        <div class="col-md-12 col-12 m-auto ">
            <div class="d-flex ">
                <div class=" mb-md-4  me-3" *ngFor="let item of testimonials ;let i = index" data-aos="fade-up">
                    <div class=" bg-white border-gray-2 rounded-16 user-info-card p-md-4 p-3 mb-md-0 mb-5">
                        <div class="d-flex align-items-center mb-3 justify-content-between">
                            <div class="d-flex align-items-center">
                                <div>

                                    <img [src]="'https://randomuser.me/api/portraits/men/' + (i + 1) + '.jpg'"
                                        alt="user" class="user-img">

                                </div>
                                <div class=" ms-3">

                                    <p class="font-weight-500 font-18  mb-0 ">{{item.name}}</p>
                                    <p class=" color-gray-2 font-14 mb-0">{{item.position}}</p>
                                </div>
                            </div>

                            <!-- <div>
                                <img src="../../../../assets/images/clients/five-star.svg" alt="stars">
                            </div> -->
                        </div>

                        <div class="font-14 color-gray-2 mb-0 break-word">
                            <div> {{item.review}}
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>

    <div class="row mt-md-4 mt-3 scroll-container-2">
        <div class="col-md-12 col-12 m-auto ">
            <div class="d-flex ">
                <div class=" mb-md-4  me-3" *ngFor="let item of testimonials2; let i = index" data-aos="fade-up">
                    <div class=" bg-white border-gray-2 rounded-16 user-info-card-2 p-md-4 p-3 mb-md-0 mb-5">
                        <div class="d-flex align-items-center mb-3 justify-content-between">
                            <div class="d-flex align-items-center">
                                <div>

                                    <img [src]="'https://randomuser.me/api/portraits/men/' + (i + 30) + '.jpg'"
                                        alt="user" class="user-img">

                                </div>
                                <div class=" ms-3">

                                    <p class="font-weight-500 font-18  mb-0 ">{{item.name}}</p>
                                    <p class=" color-gray-2 font-14 mb-0">{{item.position}}</p>
                                </div>
                            </div>

                            <!-- <div>
                                <img src="../../../../assets/images/clients/five-star.svg" alt="stars">
                            </div> -->
                        </div>

                        <div class="font-14 color-gray-2 mb-0 break-word">
                            <div> {{item.review}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>





</div>

























<div class="container-fluid " data-aos="fade-up">





    <div class="row pb-md-4 pb-3 pt-md-5 pt-3 mt-md-5 mx-auto" style="max-width: 1440px;">
        <div class="col-md-10 col  py-md-5 py-3 mx-auto">
            <p class="color-purple font-14 font-weight-600 mb-0"> <span class="styled-14 px-3">CONTACT US </span></p>
            <p class="font-28 font-weight-600 color-purple">LET'S CONNECT </p>
            <p class="color-gray-2">Fill the form below and we will be in touch with you shortly</p>

        </div>




    </div>

    <div class="row pb-md-4 pb-3 pt-md-5 pt-3  background-purple">
        <div class="col-md-10 col  text-white  p-md-4 p-3  mx-auto" style="max-width: 1440px;">
            <div class="row mb-md-5 mb-3 ">
                <div class="col-md-6 col-12">
                    <div class="d-flex mb-md-4 mb-2">
                        <div class="me-3">
                            <img src="../../../../assets/images/clients/location.svg" alt="location.svg">
                        </div>
                        <div>
                            <p class="   mb-0">

                                41/75 Thaper Colony
                            </p>
                            <p>
                                Jagadhri, (135001)

                            </p>
                        </div>
                    </div>

                    <div class="mb-md-5 mb-2">
                        <p class=" mb-0">
                            <img src="../../../../assets/images/clients/mobile.svg" alt="mobile.svg">
                            +91 8837624775 , +91 7709727784
                        </p>
                    </div>

                    <div class="d-flex ">
                        <div class="me-3">
                            <img src="../../../../assets/images/clients/mail.svg" alt="mail">

                        </div>
                        <div>
                            <p class="mb-0 text-white">
                                <a href="mailto:hi@thecodefusion.com" class="text-white">hi@thecodefusion.com</a>
                            </p>
                            <p>
                                <a href="mailto:shivam@thecodefusion.com"
                                    class="text-white">shivam@thecodefusion.com</a>
                            </p>

                        </div>
                    </div>

                    <p class="">
                    </p>
                </div>
                <div class="col-md-6 col-12">

                    <div class="row px-md-4 px-2 py-md-5 py-3  rounded-20 bg-white box-shadow">
                        <div class="col-md-6"><input type="text" placeholder="Full Name"></div>
                        <div class="col-md-6">
                            <input type="text" placeholder="Email Name">
                        </div>
                        <div class="col-md-6">
                            <input type="text" placeholder="Phone Number ">
                        </div>
                        <div class="col-md-6">
                            <input type="text" placeholder="Skype id / whatsapp number">
                        </div>
                        <div class="col-md-6">
                            <input type="text" placeholder="Interested in">
                        </div>
                        <div class="col-md-6">
                            <input type="text" placeholder="Your Budget">
                        </div>
                        <div class="col-md-12">
                            <textarea placeholder="Enter your query" class="custom-textarea"></textarea>
                        </div>
                        <div>

                        </div>
                        <div class="d-flex col-md-12 justify-content-end">

                            <button type="submit" class="submit-btn ">
                                Submit <img src="../../../../assets/images/clients/white-right-arrow.svg" alt="arrow"
                                    class="ms-3">
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        </div>




    </div>

</div>












<div class="container-fluid  mt-md-5 mt-3" data-aos="fade-up">
    <div class="row mx-auto" style="max-width: 1440px;">
        <div class="col-md-7  m-auto bg-white  p-md-4 p-3  text-center">
            <p class="color-purple font-14 font-weight-600 "><span class="styled-14 px-2">Recents Posts</span></p>

            <p class="color-gray font-weight-600 font-28">News Feeds</p>
            <p class="color-gray">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's standard dummy text ever since the 1500s, when an unknown printer</p>

        </div>
    </div>
</div>










<div class="container-fluid  mb-md-5 mb-3" data-aos="fade-up">
    <div class="row mx-auto" style="max-width: 1440px;">
        <div class="col-md-10 m-auto scroll-container-2 p-0">
            <div class="custom-card-2-parent d-flex ">
                <div class=" custom-card-2-child border-gray me-3 rounded-bottom-12" *ngFor="let item of limitLoop;let i=index">

                    <div class="width-400">

                        <img src="../../../../assets/images/clients/image-1.png" alt="image" *ngIf="i===0" class="rounded-top-12">
                        <img src="../../../../assets/images/clients/i1.png" alt="image" *ngIf="i===1" class="rounded-top-12">
                        <img src="../../../../assets/images/clients/i2.png" alt="image" *ngIf="i===2" class="rounded-top-12">


                    </div>
                    <div class="p-md-4 p-3">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <img src="../../../../assets/images/clients/user-img.png" alt="user" class="rounded-50">
                                <p class="color-purple mb-0 ms-3 font-14 font-weight-500 ">Aurthur John</p>
                            </div>
                            <div class="styled-date font-14 px-2">
                                23 Oct 2023
                            </div>
                        </div>
                        <div>
                            <p class="font-18 font-weight-600 mt-3 color-gray">We are the next global
                                software development revolution</p>
                            <p class="font-16 color-gray-2">Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem
                                Ipsum has been the industry's standard...</p>

                        </div>
                        <div>
                            <button
                                class="get-quote-btn bg-transparent color-purple border-white font-weight-500 px-0 underline">Learn
                                More <img src="../../../../assets/images/clients/purple-arrow-right.svg" alt="">
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>


</div>